import * as React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ErrorIcon from '@mui/icons-material/Error';

const Nav = () => (
  <List component="nav">
    <ListItemButton component={Link} to="/reports">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/reports/revenue">
      <ListItemIcon>
        <AttachMoneyIcon />
      </ListItemIcon>
      <ListItemText primary="Revenue" />
    </ListItemButton>
    <ListItemButton component={Link} to="/reports/subscriptions">
      <ListItemIcon>
        <SubscriptionsIcon />
      </ListItemIcon>
      <ListItemText primary="Subscriptions" />
    </ListItemButton>
    <ListItemButton component={Link} to="/reports/problems">
      <ListItemIcon>
        <ErrorIcon />
      </ListItemIcon>
      <ListItemText primary="Problems" />
    </ListItemButton>
  </List>
);

export default Nav;
