import { connect } from 'react-redux';
import WPESite from '../../components/hosted-sites/WPESite';
import { fetchSites, wpeSiteAction, clearLogMessages } from '../../actions';

const mapStatetoProps = ({ entities, ui }, ownProps) => {
  const { match: { params: { siteId } } } = ownProps;
  return {
    site: entities.wpe_sites.get(siteId),
    messages: ui.getIn(['wpe', 'messageLog', 'messages']),
    runningAction: ui.getIn(['wpe', 'runningAction']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHostedSites: () => dispatch(fetchSites()),
  runAction: (installName, action, args = {}) => dispatch(wpeSiteAction(installName, action, args)),
  clearLog: () => dispatch(clearLogMessages()),
});

export default connect(mapStatetoProps, mapDispatchToProps)(WPESite);
