import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Field, reduxForm } from 'redux-form';
import './css/RefundForm.css';

/**
 * @param {Number} refundAmount
 * @param {Array} hidden - refund amount values that add a hidden class
 * @return {String}
 */
function refundAmountClasses(refundAmount, hidden) {
  const classNames = ['form-group'];
  if (hidden.indexOf(refundAmount) !== -1) {
    classNames.push('hidden');
  }
  return classNames.join(' ');
}

/**
 * @param {Boolean} submitting
 * @param {String} amountId - 110%, 100%, partial, bluehost
 * @param {String} type - return,courtesy,bluehost
 * @param {Number} partial
 */
function disabled(submitting, amountId, type, partial) {
  if (submitting || !amountId) {
    return true;
  }

  if (amountId === '100%') {
    return !type || type === 'Refund Type';
  }

  if (amountId === 'partial') {
    return !type || !partial || type === 'Refund Type';
  }

  return false;
}

/**
 * @param {String} amountId
 * @return {String}
 */
function getNoteClasses(amountId) {
  const classNames = ['refund-note'];
  if (!amountId || amountId === 'Refund Amount') {
    classNames.push('hidden');
  }
  return classNames.join(' ');
}

/**
 * Handles submitting the refund via the form
 *
 * @param {Function} refund
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} order
 * @param {Object} values
 * @param {Object} currentRefunds
 * @return {Promise}
 */
function submit(refund, lineItem, order, values, currentRefunds) {
  const { refundAmount, refundType, refundInput, refundNote } = values;
  return refund(lineItem, order, {
    amountId: refundAmount,
    type: refundType,
    partial: refundInput || 0,
    note: refundNote ? refundNote.trim() : '',
  }, currentRefunds);
}

const RefundForm = ({
  handleSubmit,
  refundAmount,
  refundType,
  refundInput,
  lineItem,
  refund,
  order,
  submitting,
  refunds,
}) => (
  <form
    className="form-inline refund-form"
    onSubmit={handleSubmit(values => submit(refund, lineItem, order, values, refunds))}
  >
    <div className="refund-form-inner">
      <button
        disabled={disabled(submitting, refundAmount, refundType, refundInput)}
        className="btn btn-primary"
        type="submit"
      >
        refund
      </button>
      <div className="form-group">
        <Field name="refundAmount" id="refundAmount" component="select">
          <option>Refund Amount</option>
          <option value="110%">110%</option>
          <option value="100%">100%</option>
          <option value="partial">partial</option>
          <option value="bluehost">30.00 (bluehost)</option>
        </Field>
      </div>
      <div className={refundAmountClasses(refundAmount, ['110%', 'bluehost'])}>
        <Field name="refundType" id="refundType" component="select">
          <option>Refund Type</option>
          <option value="return">return</option>
          <option value="courtesy">courtesy</option>
        </Field>
      </div>
      <div className={refundAmountClasses(refundAmount, ['110%', 'bluehost', '100%'])}>
        <label htmlFor="refundInput">Amount</label>
        <Field
          name="refundInput"
          id="refundInput"
          component="input"
          type="text"
          placeholder="20.00"
        />
      </div>
    </div>
    <div className={getNoteClasses(refundAmount)}>
      <label htmlFor="refundNote">Note</label>
      <Field
        id="refundNote"
        name="refundNote"
        component="textarea"
        cols={68}
        rows={6}
      />
    </div>
  </form>
);

RefundForm.propTypes = {
  lineItem: PropTypes.instanceOf(Immutable.Map).isRequired,
  updateUiValue: PropTypes.func.isRequired,
  refund: PropTypes.func.isRequired,
  refundAmount: PropTypes.string,
  refundType: PropTypes.string,
  refundInput: PropTypes.string,
  refunds: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default reduxForm({})(RefundForm);
