import moment from 'moment';
import { fromJS } from 'immutable';

export const subscriptionReportColumns = [
  { id: 'month', name: 'By Month' },
  { id: 'newSubs', name: 'New Subscriptions' },
  { id: 'newSites', name: 'New Sites' },
  { id: 'cancellationRate', name: 'Cancellation Rate' },
  { id: 'cancelledSubs', name: 'Subscriptions Cancelled' },
  { id: 'cancelledSites', name: 'Sites Cancelled' },
  { id: 'pendingSubs', name: 'Subscriptions Leaving' },
  { id: 'pendingSites', name: 'Sites Leaving' },
  { id: 'netSubs', name: 'Net Subscriptions' },
  { id: 'netSites', name: 'Net Sites' },
  { id: 'totalSites', name: 'Running Site Total' },
];

export const getSiteCountFromSub = sub => {
  const count = sub
    .get('line_items')
    .find(li => li.get('product_id') === 322)
    .get('sku', '')
    .replace(/[^0-9]+/gi, '');
  return parseInt(count, 10);
}

const getRow = (month) => {
  const prettyMonth = moment(month, 'YYYYMM').format('MMM, YYYY');
  return {
    id: month,
    month: prettyMonth,
    newSubs: 0,
    newSites: 0,
    cancelledSubs: 0,
    cancelledSites: 0,
    pendingSubs: 0,
    pendingSites: 0,
    netSubs: 0,
    netSites: 0,
    totalSites: 0,
  };
}

const getSiteCosts = (currentCosts, total, discount, siteCount, status) => {
  if (status !== 'active') {
    return currentCosts;
  }

  const totalInt = parseInt(total, 10);
  const discountInt = parseInt(discount, 10);
  const costs = {};

  if (siteCount === 1) {
    costs[totalInt] = (currentCosts[totalInt] || 0) + 1;
  } else {
    const firstSiteCost = 39 - discountInt;
    const additionalSiteCount = siteCount - 1;
    costs[firstSiteCost] = (currentCosts[firstSiteCost] || 0) + 1;
    const additionalSiteCost = (totalInt - firstSiteCost) / additionalSiteCount;
    costs[additionalSiteCost] = (currentCosts[additionalSiteCost] || 0) + additionalSiteCount;
  }

  return costs;
}

const updateData = (data, month, sub) => {
  const status = sub.get('status');
  const siteCount = getSiteCountFromSub(sub);
  const currentRow = data.monthly[month];
  const currentTotals = data.totals;
  const currentCosts = data.costs;

  const updatedRow = {
    newSubs: currentRow.newSubs + 1,
    newSites: currentRow.newSites + siteCount,
  }
  const updatedTotals = {
    newSubs: currentTotals.newSubs + 1,
    newSites: currentTotals.newSites + siteCount,
  }

  switch(status) {
    case 'cancelled': {
      updatedRow.cancelledSubs = currentRow.cancelledSubs + 1;
      updatedRow.cancelledSites = currentRow.cancelledSites + siteCount;
      updatedTotals.cancelledSubs = currentTotals.cancelledSubs + 1;
      updatedTotals.cancelledSites = currentTotals.cancelledSites + siteCount;
      break;
    }
    case 'on-hold':
    case 'pending-cancel': {
      updatedRow.pendingSubs = currentRow.pendingSubs + 1;
      updatedRow.pendingSites = currentRow.pendingSites + siteCount;
      updatedTotals.pendingSubs = currentTotals.pendingSubs + 1;
      updatedTotals.pendingSites = currentTotals.pendingSites + siteCount;
      break;
    }
    case 'active': {
      updatedRow.netSubs = currentRow.netSubs + 1;
      updatedRow.netSites = currentRow.netSites + siteCount;
      updatedTotals.netSubs = currentTotals.netSubs + 1;
      updatedTotals.netSites = currentTotals.netSites + siteCount;
      break;
    }
    default:
      // ¯\_(ツ)_/¯
  }

  data.monthly[month] = {
    ...currentRow,
    ...updatedRow,
  };
  data.totals = {
    ...currentTotals,
    ...updatedTotals,
  };
  data.costs = {
    ...currentCosts,
    ...getSiteCosts(currentCosts, sub.get('total'), sub.get('discount_total'), siteCount, status),
  }
};

const initializedData = () => {
  return {
    monthly: {},
    totals: subscriptionReportColumns.reduce((acc, col) => ({ ...acc, [col.id]: 0 }), {}),
    yearly: {},
    costs: {},
  };
};

const monthlySortedByDate = (monthly) => {
  return Object.values(monthly).sort((a, b) => {
    return a.id > b.id ? 1 : -1;
  })
}

const monthlyWithRunningTotals = monthly => {
  let total = 0;
  const monthlyWithTotal = [];
  monthly.forEach(row => {
    const newTotal = total + row.netSites;
    monthlyWithTotal.push({
      ...row,
      totalSites: newTotal,
    });
    total = newTotal;
  });
  return monthlyWithTotal; 
}

const monthlyWithCancellationRates = monthly => {
  const monthlyWithRates = [];
  monthly.forEach(row => {
    const rate = `${Math.round((row.cancelledSubs / row.newSubs) * 100)}%`;
    monthlyWithRates.push({
      ...row,
      cancellationRate: rate,
    });
  });
  return monthlyWithRates;
}

const totalsWithCancellationRate = totals => {
  return {
    ...totals,
    cancellationRate: `${Math.round((totals.cancelledSubs / totals.newSubs) * 100)}%`,
  };
}

const getYearlyTotals = monthly => {
  const yearlyTotals = monthly.reduce((yearly, monthRow) => {
    const year = monthRow.id.slice(0, 4);
    if (yearly[year] === undefined) {
      yearly[year] = { netSubs: 0, netSites: 0 };
    }

    yearly[year].id = year;
    yearly[year].netSubs = yearly[year].netSubs + monthRow.netSubs;
    yearly[year].netSites = yearly[year].netSites + monthRow.netSites;
    return yearly;
  }, {});
  return Object.values(yearlyTotals);
}

export const getSubscriptionData = (subscriptions: Map) => {
  const data = initializedData();
  subscriptions.valueSeq().forEach(sub => {
    const month = moment(sub.get('date_created')).format('YYYYMM');
    if (!data.monthly[month]) {
      data.monthly[month] = getRow(month);
    }
    updateData(data, month, sub);
  });
  data.monthly = monthlySortedByDate(data.monthly)
  data.monthly = monthlyWithRunningTotals(data.monthly);
  data.monthly = monthlyWithCancellationRates(data.monthly);
  data.totals = totalsWithCancellationRate(data.totals);
  data.yearly = getYearlyTotals(data.monthly);
  return fromJS(data);
};

const getMonthSalesData = (year, month, designSales) => {
  const longMonthNum = moment().month(month - 1).format('MM');
  const data = {
    date: moment(`${year}-${longMonthNum}-01`).format('MMM, YYYY'),
    sales: 0,
    total: 0,
  };
  const dateString = `${year}${month < 10 ? '0' + month : month}`;
  const monthSales = designSales.get(dateString, undefined);
  if (!monthSales) {
    return data;
  }
  
  data.sales = monthSales.reduce((sales, month) => (sales + month.get('sales', 0)), 0);
  data.total = monthSales.reduce((total, month) => (total + month.get('total', 0)), 0);
  return data;
}

export const getDesignSalesData = (designSales: Map) => {
  const sortedSales = designSales.toOrderedMap().sortBy((v, k) => k);
  let salesData = [];
  const oldest = sortedSales.keySeq().first('');
  const latest = sortedSales.keySeq().last('');
  const oldestYear = parseInt(oldest.slice(0,4), 10);
  const latestYear = parseInt(latest.slice(0,4), 10);
  
  for (let year = oldestYear; year <= latestYear; year++) {
    let firstMonth = 1;
    let lastMonth = 12;
    let yearlyTotalSales = 0;
    let yearlyTotalRevenue = 0;
    if (year === oldestYear) {
      firstMonth = parseInt(oldest.slice(-2), 10);
    } else if (year === latestYear) {
      lastMonth = parseInt(latest.slice(-2), 10);
    }
    for (let month = firstMonth; month <= lastMonth; month++) {
      const monthlySalesData = getMonthSalesData(year, month, sortedSales);
      yearlyTotalSales += monthlySalesData.sales;
      yearlyTotalRevenue += monthlySalesData.total;
      salesData.push(monthlySalesData);
      if (month === lastMonth) {
        salesData.push({
          date: `${year} Totals`,
          sales: yearlyTotalSales,
          total: yearlyTotalRevenue,
          isTotals: true,
        });
      }
    }
  }

  return salesData;
}
