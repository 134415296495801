import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import LineItem from './LineItem';
import './css/LineItemList.css';
import * as order from '../../lib/order';

/**
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} products
 * @return {Immutable.Map} product
 */
function findProduct(lineItem, products) {
  const productId = lineItem.getIn(['relationships', 'product', 'data', 'id']);
  return products.get(productId);
}

/**
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} refunds
 * @return {Immutable.Map}
 */
function findRefunds(lineItem, refunds) {
  return refunds.filter(r => {
    const lineItemId = r.getIn(['relationships', 'line_item', 'data', 'id']);
    return lineItemId === lineItem.get('id');
  });
}

/**
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} orders
 * @return {Immutable.Map}
 */
function findOrder(lineItem, orders) {
  const orderId = lineItem.getIn(['relationships', 'order', 'data', 'id']);
  return orders.get(orderId);
}

/**
 * @param {Immutable.Map} lineItem 
 * @param {Immutable.OrderedMap} blacklist 
 * @returns {string}
 */
function getBlacklistId(lineItem, blacklist) {
  const lineItemId = lineItem.get('id', '');
  const record = blacklist.find(record => record.getIn(['attributes', 'line_item_id'], '') === lineItemId);
  if (record instanceof Immutable.OrderedMap) {
    return record.get('id', '');
  }
  return '';
}

const LineItemList = ({
  lineItems,
  products,
  refunds,
  ui,
  updateUiValue,
  refund,
  orders,
  notes,
  upgradeAuths,
  lineItemsBlacklist,
}) => (
  <div className="line-item-list">
    <header>
      <h1>Purchase History</h1>
    </header>
    <ul>
      { lineItems.valueSeq().map(item => (
        <LineItem
          key={item.get('id')}
          lineItem={item}
          order={findOrder(item, orders)}
          product={findProduct(item, products)}
          refunds={findRefunds(item, refunds)}
          notes={notes}
          formUi={ui.getIn(['refundForms', item.get('id')])}
          updateUiValue={updateUiValue}
          upgradeAuth={order.upgradeAuth(item, upgradeAuths)}
          sourceUpgradeAuth={order.sourceUpgradeAuth(item, upgradeAuths)}
          blacklistId={getBlacklistId(item, lineItemsBlacklist)}
        />
      )) }
    </ul>
  </div>
);

LineItemList.propTypes = {
  lineItems: PropTypes.instanceOf(Immutable.Map).isRequired,
  orders: PropTypes.instanceOf(Immutable.Map).isRequired,
  notes: PropTypes.instanceOf(Immutable.Map).isRequired,
  updateUiValue: PropTypes.func.isRequired,
  lineItemsBlacklist: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default LineItemList;
