import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import './css/Registration.css';

function className(registration) {
  const classNames = ['registration'];
  if (registration.get('deleted_at')) {
    classNames.push('registration--deleted');
  }
  return classNames.join(' ');
}

const Registration = ({ registration, product, deleting, deleteRegistration }) => (
  <tr className={className(registration)}>
    <td>
      <a target="_blank" rel="noopener noreferrer" href={registration.get('url')}>
        {registration.get('url')}
      </a>
    </td>
    <td>{product.getIn(['attributes', 'name'])}</td>
    <td>{registration.get('site_updated_at')}</td>
    <td>{registration.get('created_at')}</td>
    <td>
      <ul>
      {registration.get('old_urls').map(u =>
        <li key={u}>
          <a target="_blank" rel="noopener noreferrer" href={u}>{u}</a>
        </li>
      )}
      </ul>
    </td>
    { !registration.get('deleted_at') &&
    <td>
      <button
        type="button"
        className="btn btn-danger"
        disabled={deleting}
        onClick={deleteRegistration}
      >
        delete
      </button>
    </td>
    }
  </tr>
);

Registration.propTypes = {
  registration: PropTypes.instanceOf(Immutable.Map),
  product: PropTypes.instanceOf(Immutable.Map),
  deleting: PropTypes.bool,
  deleteRegistration: PropTypes.func.isRequired,
};

export default Registration;
