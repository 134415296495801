import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const disconnectSiteWhitelistRegex = /downshift|undefined|erinnoel|help-prophoto/;

const SiteConnectionButton = ({ subscription, unconnectedWpeSites, connectUnconnectedWpeSite }) => {
  const [siteId, setSiteId] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const handleConnect = async (wpUserId, unconnectedWpeSite) => {
    setIsRunning(true);
    await connectUnconnectedWpeSite(wpUserId, unconnectedWpeSite);
    setIsRunning(false);
  }
  const selectedSite = unconnectedWpeSites.find(site => site.get('id') === siteId);
  const selectedEnvironment = selectedSite ? selectedSite.get('installs').find(install => install.get('environment') === 'production') : null;

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>Unconnected WPE Site</InputLabel>
        <Select
          value={siteId}
          label="Unconnected WPE Site"
          onChange={(e) => setSiteId(e.target.value)}
        >
          {unconnectedWpeSites.filter(site => !site.get('name').match(disconnectSiteWhitelistRegex)).map((site) => {
            return (
              <MenuItem key={site.get('id')} value={site.get('id')}>
                {site.get('name')}
              </MenuItem>
            );
          })}
        </Select>
        {isRunning && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '36.5px' }}>
            <CircularProgress size={20} />
          </Box>
        )}
        {!isRunning && selectedSite && selectedEnvironment && (
          <Button onClick={() => handleConnect(subscription.get('customer_id'), unconnectedWpeSites.find(site => site.get('id') === siteId))}>
            Connect
          </Button>
        )}
        {!isRunning && selectedSite && !selectedEnvironment && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '36.5px' }}>
            <Typography variant="body2" sx={{ color: 'error.main' }}>No production environment</Typography>
          </Box>
        )}
      </FormControl>
    </div>
  )
}
  
  export default SiteConnectionButton;