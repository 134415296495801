import * as products from './product-constants';

/**
 * Get a payment gateway lookup url
 *
 * @param {Immutable.Map} order
 * @return {String}
 */
export function gatewayUrl(order) {
  const txnId = order.getIn(['attributes', 'transaction_id']);

  if (gateway(order) === 'PayPal') {
    return `https://www.paypal.com/activity/payment/${txnId}`;
  }

  const merchantId = process.env.REACT_APP_BRAINTREE_MERCHANT_ID;
  return `https://www.braintreegateway.com/merchants/${merchantId}/transactions/${txnId}`;
}

/**
 * Get the orders gateway (processor)
 *
 * @param {Immutable.Map} order
 * @return {String}
 */
export function gateway(order) {
  const txnId = order.getIn(['attributes', 'transaction_id']);
  return txnId.length < 17 ? 'Braintree' : 'PayPal';
}

/**
 * Is the order failed?
 *
 * @param {Immutable.Map} order
 * @return {Boolean}
 */
export function isFailed(order) {
  return !order.getIn(['attributes', 'transaction_id']);
}

/**
 * Get the tax for an order line item
 *
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} order
 * @return {Number}
 */
export function lineItemTax(lineItem, order) {
  const orderTax = order.getIn(['attributes', 'tax']);
  if (orderTax === 0) {
    return 0;
  }

  const lineItemCost = lineItem.getIn(['attributes', 'cost']);
  const orderTotal = order.getIn(['attributes', 'total']) - orderTax;
  return (lineItemCost / orderTotal) * orderTax;
}

/**
 * Find an upgrade auth for this line item
 *
 * This means we're looking for an upgrade_auth indicating that
 * this line item was used to authenticate the purchase of an upgrade
 * in a different order.
 *
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} auths - a map of upgrade auths keyed by id
 * @return {Immutable.Map}
 */
export function upgradeAuth(lineItem, auths) {
  const id = lineItem.get('id');
  return auths
    .find(auth => auth.getIn(['relationships', 'line_item', 'data', 'id']) === id);
}

/**
 * Find an upgrade auth used to purchase this line item
 *
 * This means we're looking for an upgrade_auth indicating that
 * this line item was an upgrade and belonged to an order pointed
 * to by an upgrade_auth. IE, this returns the upgrade auth that made
 * the purchase of this line item possible.
 *
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} auths - a map of upgrade auths keyed by id
 * @return {Immutable.Map}
 */
export function sourceUpgradeAuth(lineItem, auths) {
  if (!isUpgrade(lineItem)) {
    return void 0;
  }

  const orderId = lineItem.getIn(['relationships', 'order', 'data', 'id']);
  return auths
    .find(auth => {
      return auth.getIn(['relationships', 'order', 'data', 'id']) === orderId;
    });
}

/**
 * Does this line item represent a registerable product?
 *
 * @param {Immutable.Map} lineItem
 * @return {Boolean}
 */
export function isRegisterable(lineItem) {
  const productId = lineItem.getIn(['relationships', 'product', 'data', 'id']);
  const registerable = [
    products.PROPHOTO_7,
    products.PROPHOTO_6,
    products.PROPHOTO_5,
    products.PROPHOTO_4,
    products.PROPHOTO_7_UPGRADE,
    products.PROPHOTO_6_UPGRADE,
    products.PROPHOTO_5_UPGRADE,
    products.PROPHOTO_4_UPGRADE,
    products.PROPHOTO_7_DISCOUNTED_UPGRADE,
    products.PROPHOTO_6_DISCOUNTED_UPGRADE,
    products.PROPHOTO_5_DISCOUNTED_UPGRADE,
    products.PROPHOTO_4_DISCOUNTED_UPGRADE,
    products.PROPHOTO_5_WITH_INSTALL,
    products.PROPHOTO_4_WITH_INSTALL,
    products.FOOTER_LINKS_REMOVAL,
    products.PROOFING_PLUGIN,
  ];

  return registerable.indexOf(productId) !== -1;
}

/**
 * Does this line item represent an upgrade product?
 *
 * @param {Immutable.Map} lineItem
 * @return {Boolean}
 */
export function isUpgrade(lineItem) {
  const productId = lineItem.getIn(['relationships', 'product', 'data', 'id']);
  const upgrades = [
    products.PROPHOTO_7_UPGRADE,
    products.PROPHOTO_6_UPGRADE,
    products.PROPHOTO_5_UPGRADE,
    products.PROPHOTO_4_UPGRADE,
    products.PROPHOTO_3_UPGRADE,
    products.PROPHOTO_2_UPGRADE,
    products.PROPHOTO_7_DISCOUNTED_UPGRADE,
    products.PROPHOTO_6_DISCOUNTED_UPGRADE,
    products.PROPHOTO_5_DISCOUNTED_UPGRADE,
    products.PROPHOTO_4_DISCOUNTED_UPGRADE,
    products.PROPHOTO_3_DISCOUNTED_UPGRADE,
  ];

  return upgrades.indexOf(productId) !== -1;
}

/**
 * Is this a line item for P7?
 * @param {Immutable.Map} lineItem 
 */
export function isP7(lineItem) {
  const productId = lineItem.getIn(['relationships', 'product', 'data', 'id']);
  return [
    products.PROPHOTO_7_DISCOUNTED_UPGRADE,
    products.PROPHOTO_7_UPGRADE,
    products.PROPHOTO_7,
  ].includes(productId);
}
