import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Email from './Email';

const UserEmailList = ({ emails, visible }) => (
  <div className={'user-email-list' + (visible ? ' is-visible' : '')}>
    <table className="table table-striped">
      <thead>
        <tr>
          <th>address</th>
          <th>payment</th>
          <th>contact</th>
          <th>verified</th>
          <th>created</th>
        </tr>
      </thead>
      <tbody>
        { emails.valueSeq().map(email => (
          <Email key={email.get('id')} email={email} />
        ))
        }
      </tbody>
    </table>
  </div>
);

UserEmailList.propTypes = {
  emails: PropTypes.instanceOf(Immutable.Map).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default UserEmailList;
