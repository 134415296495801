export const PROPHOTO_7 = '78123b36-2793-11e8-bd74-4feaa88dc844';
export const PROPHOTO_6 = 'e5642669-a064-4e09-8222-dd12ee26bd4c';
export const PROPHOTO_5 = '5e4fa489-c4fe-41bc-bae2-83f7a57e8861';
export const PROPHOTO_4 = 'bd75d149-1cc2-478f-9f60-3713a037e96e';
export const PROPHOTO_3 = '3a459971-d22a-4916-89ef-e14313ccc127';
export const PROPHOTO_7_UPGRADE = '88eede3c-2793-11e8-bd74-4feaa88dc844';
export const PROPHOTO_6_UPGRADE = 'de9b00fc-8cb4-479a-acb5-a381ea5ee4f2';
export const PROPHOTO_5_UPGRADE = '1878401e-fa10-428f-91f1-51eb70a6ca16';
export const PROPHOTO_4_UPGRADE = 'a2985fc3-d7d8-481b-866e-600524bff411';
export const PROPHOTO_3_UPGRADE = '431025c7-117a-47c8-9493-959c38a876fb';
export const PROPHOTO_2_UPGRADE = '611077dd-9aad-468a-b02c-bdbca63f6eb1';
export const PROPHOTO_7_DISCOUNTED_UPGRADE = 'cc268eb6-2793-11e8-bd74-4feaa88dc844';
export const PROPHOTO_6_DISCOUNTED_UPGRADE = 'df58a50d-fd4a-4d47-a2da-640483ea2fa2';
export const PROPHOTO_5_DISCOUNTED_UPGRADE = '08d85657-d649-424c-a0a4-0b21b4530125';
export const PROPHOTO_4_DISCOUNTED_UPGRADE = '8a48f036-8ad1-4ea5-bae6-9349594ba610';
export const PROPHOTO_3_DISCOUNTED_UPGRADE = '71896918-afe5-47de-87ad-51e2c7f6098d';
export const PROPHOTO_5_WITH_INSTALL = '5ffa9143-0c66-40d7-8151-12c5583c8e8b';
export const PROPHOTO_4_WITH_INSTALL = 'ffc3b49e-4f53-4021-b05a-8c27a85afc00';
export const PROPHOTO_3_WITH_INSTALL = '6e589cd9-b0ac-4195-8491-3bc501cf9ceb';
export const EXPERT_INSTALL = '47201822-bda5-4566-8bbd-71f650580143';
export const FOOTER_LINKS_REMOVAL = 'b40ce50c-caf6-404b-8fe9-ac509f71c968';
export const PROOFING_PLUGIN = '1ba0c0ca-0931-4452-b396-bf8c50c8bcfb';
