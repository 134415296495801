import Immutable from 'immutable';
import * as constants from './constants';
import { entityMap } from '../lib/json';

export const DEFAULT_ENTITY_STATE = {
  users: Immutable.Map(),
  user_emails: Immutable.Map(),
  line_items: Immutable.Map(),
  orders: Immutable.Map(),
  refunds: Immutable.Map(),
  products: Immutable.Map(),
  registrations: Immutable.Map(),
  sites: Immutable.Map(),
  site_urls: Immutable.Map(),
  notes: Immutable.Map(),
  upgrade_auths: Immutable.Map(),
  sales: Immutable.Map(),
  blacklisted_line_items: Immutable.Map(),
  wpe_sites: Immutable.Map(),
  hosting_accounts: Immutable.Map(),
  hosted_sites: Immutable.Map(),
  wc_orders: Immutable.Map(),
  wc_subscriptions: Immutable.Map(),
  wc_users: Immutable.Map(),
  wc_reports: Immutable.Map(),
  design_sales: Immutable.Map(),
};

/**
 * Returns a function that produces an entity descriptor. Entity
 * descriptors are used when dispatching entity related actions
 *
 * @param {Object} data - a response from the JSON API
 * @param {Object} resource spec - see lib/url.js for details
 * @return {Function}
 */
export function entityDescriptors(data, resourceSpec) {
  const keys = Object.keys(resourceSpec);
  return keys.map(key => {
    const update = resourceSpec[key];
    const location = update['location'];
    const resourceType = update['type'];
    return { resourceType, data, location };
  });
}

/**
 * @param {String} resourceType
 * @param {Object} data
 * @param {String} location
 * @return {Object}
 */
export function makeDescriptor(resourceType, data, location = 'data') {
  return {
    resourceType,
    data,
    location,
  };
}

/**
 * Convert a JSON API response into an entity map
 *
 * @param {String} type - json api resource type
 * @param {Object} data - the json api response
 * @param {String} location - the key on the response where entities are held
 * @return {Object}
 */
export function updateEntities(type, data, location = "data") {
  const entities = entityMap(type, data, location);
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: type,
    entities,
  };
}

/**
 * @param {string} entityId
 * @param {string} entityType
 * @param {Immutable.Map} entity
 * @return {Object}
 */
export function updateEntity(entityId, entityType, entity) {
  return {
    type: constants.UPDATE_ENTITY,
    entityId,
    entityType,
    entity,
  };
}

/**
 * @param {string} entityId
 * @param {string} entityType
 * @return {Object}
 */
export function deleteEntity(entityId, entityType) {
  return {
    type: constants.DELETE_ENTITY,
    entityId,
    entityType,
  };
}

/**
 * @param {String} type - json api resource type
 * @param {Object} entity - the json api resource
 * @return {Object}
 */
export function addEntity(type, entity) {
  return {
    type: constants.ADD_ENTITY,
    resourceType: type,
    entity: Immutable.fromJS(entity.data),
  };
}

/**
 * Updates multiple entities at once
 *
 * @param {Array} descriptors - a descriptor is an object of form {resourceType, data, location}
 * @return {Object}
 */
export function updateEntityBatch(descriptors) {
  const entities = descriptors.map(d => {
    const { resourceType, data } = d;
    const location = d.location || 'data';
    const entities = entityMap(resourceType, data, location);
    return { resourceType, entities };
  });

  return {
    type: constants.UPDATE_ENTITY_BATCH,
    entities,
  };
}

/**
 * Indicates an entity type is being fetched
 *
 * @param {String} type
 * @return {Object}
 */
export function receivingEntities(type) {
  return {
    type: constants.RECEIVING_ENTITIES,
    resourceType: type,
  };
}

/**
 * Indicates an entity type has completed fetching
 *
 * @param {String} type
 * @return {Object}
 */
 export function receivedEntities(type) {
  return {
    type: constants.RECEIVED_ENTITIES,
    resourceType: type,
  };
}

/**
 * Indicates an entity type has partially completed fetching
 *
 * @param {String} type
 * @param {String} percentage
 * @return {Object}
 */
 export function receivedPartialEntities(type, percentage) {
  return {
    type: constants.RECEIVED_PARTIAL_ENTITIES,
    resourceType: type,
    percentage,
  };
}

/**
 * @param {Object} wpeSitesMap - Map of wpe sites keyed by id
 * @return {Object}
 */
export function updateWpeSites(wpeSitesMap) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'wpe_sites',
    entities: wpeSitesMap,
  };
}

/**
 * @param {Object} hostedSitesMap - Map of hosted sites keyed by id
 * @return {Object}
 */
 export function updateHostedSites(hostedSitesMap) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'hosted_sites',
    entities: hostedSitesMap,
  };
}

/**
 * @param {Object} hostedSite
 * @return {Object}
 */
 export function addHostedSite(hostedSite) {
  return {
    type: constants.ADD_ENTITY,
    resourceType: 'hosted_sites',
    entity: hostedSite,
  };
}

/**
 * @param {Object} hostingAccountsMap - Map of hosting Accounts keyed by name
 * @return {Object}
 */
 export function updateHostingAccounts(hostingAccountsMap) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'hosting_accounts',
    entities: hostingAccountsMap,
  };
}

/**
 * @param {Object} wcOrdersMap - Map of WooCommerce orders keyed by id
 * @return {Object}
 */
 export function updateWcOrders(wcOrdersMap) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'wc_orders',
    entities: wcOrdersMap,
  };
}

/**
 * @param {Object} wcSubsMap - Map of WooCommerce subscriptions keyed by id
 * @return {Object}
 */
 export function updateWcSubscriptions(wcSubsMap) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'wc_subscriptions',
    entities: wcSubsMap,
  };
}

/**
 * @param {Object} wcReports - Map of WooCommerce reports keyed by report type
 * @return {Object}
 */
export function updateWcReports(wcReports) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'wc_reports',
    entities: wcReports,
  };
}

/**
 * @param {Object} designSales - Map of design sales keyed by month
 * @return {Object}
 */
export function updateDesignSales(designSales) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'design_sales',
    entities: designSales,
  };
}
