import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RefundForm from '../../components/purchases/RefundForm';
import * as actions from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { lineItem, order } = ownProps;
  const refunds = state.entities.refunds
    .filter(r => {
      const lineItemId = r.getIn(['relationships', 'line_item', 'data', 'id']);
      return lineItemId === lineItem.get('id');
    });
  const form = `RefundForm_${lineItem.get('id')}`;
  const selector = formValueSelector(form);
  return {
    lineItem,
    order,
    form,
    refunds,
    ...selector(state, 'refundAmount', 'refundType', 'refundInput', 'refundNote'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUiValue: (path, value) => dispatch(actions.updateUiValue(path, value)),
  refund: (...args) => dispatch(actions.refund(...args)),
});

const RefundFormContainer = connect(mapStateToProps, mapDispatchToProps)(RefundForm);
export default RefundFormContainer;
