import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import RefundInfo from './RefundInfo';
import RefundFormContainer from '../../containers/purchases/RefundFormContainer';
import { gatewayUrl, lineItemTax, isFailed, isRegisterable, gateway } from '../../lib/order';
import BlacklistToggleContainer from '../../containers/purchases/BlacklistToggleContainer';
import { isP7 } from '../../lib/order';
import './css/LineItem.css';

/**
 * Payment gateway lookup link list item
 *
 * @param {Immutable.Map} order
 * @return {React.Element}
 */
function paymentListItem(order) {
  if (isFailed(order)) {
    return null;
  }

  return (
    <li>
      <strong>Payment Lookup: </strong>
      <a href={gatewayUrl(order)} target="_blank" rel="noopener noreferrer">{gateway(order)}</a>
    </li>
  );
}

/**
 * Get disconnected upgrade user
 *
 * @param {Immutable.Map|null} auth
 * @param {String} relationship
 * @return {null|ReactElement}
 */
function getDisconnectedUser(auth, relationship) {
  if (!auth) {
    return null;
  }

  const userId = auth.getIn(['relationships', 'user', 'data', 'id']);
  const upgradeUserId = auth.getIn(['relationships', 'upgrade_user', 'data', 'id']);

  if (userId === upgradeUserId) {
    return null;
  }

  const disconnectedId = auth.getIn(['relationships', relationship, 'data', 'id']);

  return (
    <ul className="line-item-list__line-item__upgrade-user alert-danger">
      <li><strong>Disconnected Upgrade User:</strong></li>
      <li>
        <Link to={`/users/${disconnectedId}`}>
          { disconnectedId }
        </Link>
      </li>
    </ul>
  );
}

const LineItem = ({
  lineItem,
  product,
  refunds,
  formUi,
  updateUiValue,
  order,
  notes,
  user,
  upgradeAuth,
  sourceUpgradeAuth,
  blacklistId,
}) => {
  const isFailedOrder = isFailed(order);
  const classes = ['line-item-list__line-item'];
  if (isFailedOrder) {
    classes.push(['line-item-list__line-item--failed']);
  }
  if (blacklistId) {
    classes.push('line-item-list__line-item--blacklisted');
  }
  return (
    <li className={classes.join(' ')}>
      <div className="line-item-data">
        <ul className="line-item-data-primary">
          <li>
            <strong>Product: </strong>{product.getIn(['attributes', 'name'])}
            {isRegisterable(lineItem) &&
              <Link className="registrations" to={`/registrations/${lineItem.get('id')}`}>
                (registrations)
              </Link>
            }
          </li>
          <li>
            <strong>Line Item ID:
              <Link to={`/line-items/${lineItem.get('id')}`}>
                <code>{lineItem.get('id')}</code>
              </Link>
            </strong>
          </li>
          <li>
            <strong>Purchased: </strong>{lineItem.getIn(['attributes', 'created_at'])}
          </li>
          {paymentListItem(order)}
        </ul>
        <ul className="line-item-data-secondary">
          <li>
            <strong>Order ID: </strong>{order.get('id')}
          </li>
          <li>
            <strong>Cost: </strong>${lineItem.getIn(['attributes', 'cost']).toFixed(2)}
          </li>
          <li>
            <strong>Tax: </strong>${lineItemTax(lineItem, order).toFixed(2)}
          </li>
          { user &&
          <li>
            <strong>User ID: </strong>
            <Link to={`/users/${user.get('id')}`}>
              {user.get('id')}
            </Link>
          </li>
          }
        </ul>
      </div>
      {getDisconnectedUser(upgradeAuth, 'upgrade_user')}
      {getDisconnectedUser(sourceUpgradeAuth, 'user')}
      { !isFailedOrder && refunds.size > 0 && (
        <RefundInfo refunds={refunds} notes={notes} />
      )}
      { !isFailedOrder && <RefundFormContainer lineItem={lineItem} order={order} /> }
      {isP7(lineItem) && <BlacklistToggleContainer blacklistId={blacklistId} lineItemId={lineItem.get('id', '')} />}
    </li>
  );
};

LineItem.propTypes = {
  lineItem: PropTypes.instanceOf(Immutable.Map).isRequired,
  product: PropTypes.instanceOf(Immutable.Map).isRequired,
  refunds: PropTypes.instanceOf(Immutable.Map).isRequired,
  notes: PropTypes.instanceOf(Immutable.Map).isRequired,
  order: PropTypes.instanceOf(Immutable.Map).isRequired,
  formUi: PropTypes.object,
  updateUiValue: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Immutable.Map),
  upgradeAuth: PropTypes.instanceOf(Immutable.Map),
  sourceUpgradeAuth: PropTypes.instanceOf(Immutable.Map),
  blacklistId: PropTypes.string.isRequired,
};

export default LineItem;
