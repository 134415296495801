import React from 'react';
import PropTypes from 'prop-types';

function submit(login) {
  return (e) => {
    e.preventDefault();
    const id = document.getElementById('client-id');
    const secret = document.getElementById('client-secret');
    login(id.value, secret.value);
  }
}

const Login = ({ login }) => (
  <div className="row">
    <div className="col-xs-6">
      <h1>login</h1>
      <form onSubmit={submit(login)}>
        <div className="form-group">
          <label htmlFor="client-id">Client ID</label>
          <input type="text" className="form-control" id="client-id" placeholder="rad client" />
        </div>
        <div className="form-group">
          <label htmlFor="client-secret">Client Secret</label>
          <input type="text" className="form-control" id="client-secret" placeholder="rad client" />
        </div>
        <button type="submit" className="btn btn-default">Login</button>
      </form>
    </div>
  </div>
);

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
