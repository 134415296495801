import React, { Component } from 'react';
import Login from './components/Login';
import * as auth from './lib/auth';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = { loggedIn: false };
  }

  componentDidMount() {
    if (auth.credentialsStored()) {
      this.setState({ loggedIn: true });
    }
  }

  login(id, secret) {
    auth.login(id, secret);
    this.setState({ loggedIn: true });
  }

  render() {
    return (
      <div className="App">
        { this.state.loggedIn ?
          this.props.children :
          <Login login={this.login.bind(this)} />
        }
      </div>
    );
  }
}

export default App;
