import { receivingEntities, updateEntityBatch, makeDescriptor } from './entities';
import fetch from '../lib/fetch';
import { lineItemTax } from '../lib/order';

const Headers = window.Headers;

/**
 * @param {Number} amount
 * @param {String} type
 * @param {String} lineItemId
 * @return {Promise}
 */
function createRefund(amount, type, lineItemId) {
  const url = `${process.env.REACT_APP_API_HOST}/refunds`;
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      data: {
        type: 'refunds',
        attributes: {
          amount,
          type,
        },
        relationships: {
          line_item: {
            data: {
              type: 'line_items',
              id: lineItemId,
            }
          }
        },
      },
    })
  });
}

/**
 * @param {Object} refund
 * @param {String} text
 * @return {Promise}
 */
function createNote(refund, text) {
  const url = `${process.env.REACT_APP_API_HOST}/notes`;
  const refundId = refund.data.id;
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      data: {
        type: 'notes',
        attributes: {
          text,
        },
        relationships: {
          refund: {
            data: {
              type: 'refunds',
              id: refundId,
            },
          },
        },
      },
    }),
  });
}

/**
 * Calculates the total amount of the refund
 *
 * @param {String} amountId - 110%, 100%, bluehost, partial
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} order
 * @param {Number} partial - an amount to explicitly refund
 * @param {Immutable.Map} currentRefunds
 * @return {Number}
 */
function calcAmount(amountId, lineItem, order, partial, currentRefunds) {
  const refunded = currentRefunds.reduce((total, refund) => {
    return refund.getIn(['attributes', 'amount']);
  }, 0);
  const tax = lineItemTax(lineItem, order);
  const cost = lineItem.getIn(['attributes', 'cost']);
  const total = (cost + tax) - refunded;
  switch (amountId) {
    case 'bluehost': {
      return 30;
    }
    case '100%': {
      return total;
    }
    case '110%': {
      return total + (cost * .10);
    }
    default: {
      return partial;
    }
  }
}

/**
 * @param {String} amountId - 110%,100%,partial,bluehost
 * @param {String} type
 * @return {String}
 */
function getType(amountId, type) {
  switch (amountId) {
    case '110%': {
      return 'return';
    }
    case 'bluehost': {
      return 'bluehost';
    }
    default: {
      return type;
    }
  }
}

/**
 * Refund a line item
 *
 * @param {Immutable.Map} lineItem
 * @param {Immutable.Map} order
 * @param {Object} refundData
 */
export function refund(lineItem, order, refundData, currentRefunds) {
  const { amountId, type, partial, note } = refundData;
  const realAmount = calcAmount(amountId, lineItem, order, partial, currentRefunds);
  const realType = getType(amountId, type);
  const truthy = a => !!a;
  return (dispatch) => {
    dispatch(receivingEntities('refunds'));
    return createRefund(realAmount, realType, lineItem.get('id'))
      .then(response => response.json())
      .then(entity => [entity, makeDescriptor('refunds', entity)])
      .then(([refund, refundDescriptor]) => {
        if (! note) {
          return Promise.all([Promise.resolve({ ok: false }), refundDescriptor]);
        }
        return Promise.all([createNote(refund, note), refundDescriptor]);
      })
      .then(([response, refundDescriptor]) => {
        return Promise.all([response.ok ? response.json() : Promise.resolve(), refundDescriptor]);
      })
      .then(([entity, refundDescriptor]) => [refundDescriptor, entity && makeDescriptor('notes', entity)])
      .then(descriptors => descriptors.filter(truthy))
      .then(updateEntityBatch)
      .then(dispatch);
  };
}
