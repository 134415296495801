import React from 'react';

const Email = ({ email }) => (
  <tr>
    <td>{email.getIn(['attributes', 'address'])}</td>
    <td>{email.getIn(['attributes', 'is_payment']) ? '√' : ''}</td>
    <td>{email.getIn(['attributes', 'is_contact']) ? '√' : ''}</td>
    <td>{email.getIn(['attributes', 'is_verified']) ? '√' : ''}</td>
    <td>{email.getIn(['attributes', 'created_at'])}</td>
  </tr>
);

export default Email;
