import { receivingEntities, updateEntityBatch, entityDescriptors } from './entities';
import fetch, { handleErrors } from '../lib/fetch';
import * as url from '../lib/url';
import { updateUiValue } from './ui';

/**
 * Describes the response from the JSON API. Used
 * to update entity state
 */
const RESOURCE_SPEC = {
  users: {
    type: 'users',
    location: 'data',
  },
  emails: {
    type: 'user_emails',
    location: 'included'
  },
  'orders.line_items': {
    type: 'line_items',
    location: 'included',
  },
  'orders.line_items.refunds': {
    type: 'refunds',
    location: 'included',
  },
  'orders.line_items.product': {
    type: 'products',
    location: 'included',
  },
  orders: {
    type: 'orders',
    location: 'included',
  },
  'orders.line_items.refunds.notes': {
    type: 'notes',
    location: 'included',
  },
  'orders.line_items.upgrade_auth': {
    type: 'upgrade_auths',
    location: 'included'
  },
  'orders.upgrade_auths': {
    type: 'upgrade_auths',
    location: 'included'
  },
  'orders.line_items.blacklisted_line_items': {
    type: 'blacklisted_line_items',
    location: 'included',
  },
};

/**
 * Create a new email for the user
 *
 * @param {Immutable.Map} user
 * @param {String} address
 * @return {Promise}
 */
function createEmail(user, address) {
  const emailUrl = url.resourceUrl('emails');
  return fetch(emailUrl, {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      data: {
        type: "user_emails",
        attributes: {
          address,
          is_contact: true,
          is_payment: true,
          is_verified:  false,
        },
        relationships: {
          user: {
            data: {
              type: 'users',
              id: user.get('id'),
            },
          },
        },
      },
    }),
  });
}

/**
 * @param {String} id
 * @return {Promise}
 */
function sendEmailVerification(id) {
  const emailUrl = url.resourceUrl(`emails/${id}/verification`);
  return fetch(emailUrl, { method: 'POST', mode: 'cors' });
}

/**
 * Fetch user by id
 *
 * @param  {String} id
 */
export function fetchUser(id) {
  return (dispatch) => {
    const userUrl = url.resourceUrl(`users/${id}`, RESOURCE_SPEC);
    fetch(userUrl)
      .then(response => response.json())
      .then(data => {
        const descriptors = entityDescriptors(data, RESOURCE_SPEC);
        dispatch(updateEntityBatch(descriptors));
      });
  };
}

/**
 * Search for a user by email
 *
 * @param {URLSearchParams} params - user filter parameters
 */
export function searchUsers(params) {
  return (dispatch) => {
    dispatch(receivingEntities('users'));
    const filters = {};
    params.forEach((value, key) => {
      if (!!value) {
        filters[key] = value;
      }
    });
    const userUrl = url.resourceUrl('users', RESOURCE_SPEC, filters);
    fetch(userUrl)
      .then(response => response.json())
      .then(data => {
        const descriptors = entityDescriptors(data, RESOURCE_SPEC);
        dispatch(updateEntityBatch(descriptors));
      });
  };
}

/**
 * Associates an email address with the user.
 *
 * @param {Immutable.Map} user
 * @param {String} address
 * @return {Promise}
 */
export function associateEmail(user, address) {
  return (dispatch) => {
    dispatch(updateUiValue(['creating', 'email'], true));
    createEmail(user, address)
      .then(response => response.json(), () => dispatch(updateUiValue(['creating', 'email'], false)))
      .then(entity => sendEmailVerification(entity.data.id))
      .then(() => dispatch(searchUsers(address)))
      .then(() => dispatch(updateUiValue(['creating', 'email'], false)));
  };
}

/**
 * Performs a request to merge the user
 *
 * @param {String} targetId
 * @param {String} srcId
 * @return {Promise}
 */
function mergeUser(targetId, srcId) {
  const targetUrl = url.resourceUrl(`users/${targetId}/merge`);
  return fetch(targetUrl, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      data: {
        type: 'users',
        id: srcId
      }
    }),
  });
}

/**
 * @param {Immutable.Map} user
 * @param {String} email
 * @return {Function}
 */
export function requestMergeUser(user, email) {
  return (dispatch) => {
    dispatch(updateUiValue(['mergingUser'], true));
    const srcUrl = url.resourceUrl('users', {}, { email });
    fetch(srcUrl)
      .then(response => response.json())
      .then(json => json.data.length ? json.data : Promise.reject(new Error("User not found")))
      .then(resource => resource[0].id)
      .then(srcId => mergeUser(user.get('id'), srcId))
      .then(handleErrors)
      .then(() => dispatch(searchUsers(user.get('id'))))
      .then(() => dispatch(updateUiValue(['mergingUser'], false)))
      .catch((reason) => {
        dispatch(updateUiValue(['mergingUser'], false));
        alert(`Daaaaaaaang: ${reason.message}`);
      });
  }
}
