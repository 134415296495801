import { connect } from 'react-redux';
import WPEDashboard from '../../components/hosted-sites/WPEDashboard';
import { fetchSites, fetchAccounts, wpeAccountAction, dismissLogMessages, clearLogMessages } from '../../actions';

const mapStatetoProps = state => ({
  sites: state.entities.wpe_sites.sort((a,b) => (a.get('name') > b.get('name') ? 1 : -1)),
  hostingAccounts: state.entities.hosting_accounts.sortBy((val, key) => key),
  messages: state.ui.getIn(['wpe', 'messageLog', 'messages']),
  isLogOpen: state.ui.getIn(['wpe', 'messageLog', 'show']),
  runningAction: state.ui.getIn(['wpe', 'runningAction']),
  isFetchingHostedSites: state.ui.getIn(['fetching', 'wpe_sites']),
  isFetchingHostingAccounts: state.ui.getIn(['fetching', 'hosting_accounts']),
});

const mapDispatchToProps = (dispatch) => ({
  fetchHostedSites: () => dispatch(fetchSites()),
  fetchHostingAccounts: () => dispatch(fetchAccounts()),
  runAccountAction: (accountId, action, args) => dispatch(wpeAccountAction(accountId, action, args)),
  clearLog: () => dispatch(clearLogMessages()),
  dismissLog: () => dispatch(dismissLogMessages()),
});

const WPE = connect(mapStatetoProps, mapDispatchToProps)(WPEDashboard);
export default WPE;
