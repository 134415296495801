import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import * as url from '../../lib/url';
import fetch from '../../lib/fetch';

/**
 * Login to the user's me page
 *
 * @param {Immutable.Map} user
 * @return {Function}
 */
function login(user) {
  return (e) => {
    const button = e.currentTarget;
    button.disabled = 'disabled';
    const id = user.get('id');
    const tokenUrl = url.resourceUrl(`users/${id}/tokens`);
    const response = fetch(tokenUrl, { method: 'POST' });
    response
      .then(response => response.json())
      .then(json => json.data.attributes.value)
      .then(token => `https://pro.photo/me?token=${token}`)
      .then(uri => {
        button.removeAttribute('disabled');
        window.open(uri);
      });
  };
}

const LoginButton = ({ user }) => (
  <button onClick={login(user)} className="btn btn-primary login-button" type="button">
    Login To Me Page
  </button>
);

LoginButton.propTypes = {
  user: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default LoginButton;
