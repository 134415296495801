import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import User from '../../components/users/User';
import {
  associateEmail,
  requestMergeUser,
  updateUiValue,
  fetchUser
} from '../../actions';

function userEmails(emails, userId) {
  return emails
    .filter(e => e.getIn(['relationships', 'user', 'data', 'id']) === userId);
}

const mapStatetoProps = ({ entities, ui }, { match: { params } }) => ({
  userId: params.userId,
  user: entities.users.get(params.userId),
  emails: userEmails(entities.user_emails, params.userId),
  ui,
});

const mapDispatchToProps = (dispatch) => ({
  associateEmail: (user, address) => dispatch(associateEmail(user, address)),
  mergeUser: (user, srcEmail) => dispatch(requestMergeUser(user, srcEmail)),
  updateUiValue: (...args) => dispatch(updateUiValue(...args)),
  fetchUser: id => dispatch(fetchUser(id)),
});

const UserContainer = connect(mapStatetoProps, mapDispatchToProps)(User);
export default withRouter(UserContainer);
