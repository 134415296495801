import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import UserEmailList from './UserEmailList';
import EmailForm from './EmailForm';
import LoginButton from './LoginButton';
import './css/User.css';

class User extends React.Component {
  componentDidMount() {
    this.props.fetchUser(this.props.userId);
  }

  userName() {
    const user = this.props.user;

    if (!user) {
      return null;
    }

    const firstName = user.getIn(['attributes', 'first_name']);
    const lastName = user.getIn(['attributes', 'last_name']);

    if (!firstName || !lastName) {
      return 'Sir Whimple Humblesworth III';
    }

    return `${firstName} ${lastName}`;
  }

  userInfoClasses() {
    const classes = ['user-info'];

    if (this.props.ui.get('showingEmailList')) {
      classes.push('is-showing-email-list');
    }

    return classes.join(' ');
  }

  componentDidUpdate() {
    const { user, userId, fetchUser } = this.props;
    if (! user) {
        fetchUser(userId);
    }
  }

  render() {
    const {
      user,
      emails,
      associateEmail,
      mergeUser,
      ui,
      updateUiValue,
    } = this.props;

    return(
      <div className={this.userInfoClasses()}>
        <header>
          <a href="/users">
            <i className="fa fa-chevron-left" />
            user search
          </a>
          <h1>User: <em>{this.userName()}</em></h1>
        </header>
        { user && (
          <div className="user-info-actions">
            <div className="user-info-actions-inner">
              <LoginButton user={user} />
              <EmailForm
                heading="Merge user"
                onEmailSubmit={(email) => mergeUser(user, email)}
                disabled={ui.get('mergingUser')}
              />
              <EmailForm
                heading="Associate email"
                onEmailSubmit={associateEmail.bind(null, user)}
                disabled={ui.getIn(['creating', 'email'])}
              />
              <button
                className="btn btn-primary show-emails-button"
                onClick={() => (
                  updateUiValue(['showingEmailList'], !ui.get('showingEmailList'))
                )}
              >
                <i className="btm" />
              </button>
            </div>
          </div>
        )}
        { user && (
          <UserEmailList emails={emails} visible={ui.get('showingEmailList')} />
        )}
      </div>
    );
  }
}

User.propTypes = {
  userId: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Immutable.Map),
  emails: PropTypes.instanceOf(Immutable.Map).isRequired,
  associateEmail: PropTypes.func.isRequired,
  mergeUser: PropTypes.func.isRequired,
  ui: PropTypes.instanceOf(Immutable.Map).isRequired,
  updateUiValue: PropTypes.func.isRequired,
};

export default User;
