import React from 'react';
import LineItemsContainer from '../../containers/purchases/LineItemsContainer';
import UserContainer from '../../containers/users/UserContainer';
import './css/Dashboard.css';

class Dashboard extends React.Component {
  render() {
    return (
      <div className="user-dashboard">
        <UserContainer />
        <LineItemsContainer />
      </div>
    );
  }
}

export default Dashboard;
