import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Map, List } from 'immutable';
import IconButton from '@mui/material/IconButton';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import LogMessage from './LogMessage';
import SiteData from './SiteData';
import WPEActions from './WPEActions';
import './css/WPE-site.css';

const WPESite = ({
  site,
  runAction,
  match,
  fetchHostedSites,
  messages,
  clearLog,
  runningAction,
}) => {
  const logContainer = useRef(null);

  useEffect(() => {
    if (!site) {
      fetchHostedSites();
    }
  }, [site, fetchHostedSites]);

  useEffect(() => {
    if (!logContainer.current) {
      return;
    }
    logContainer.current.scrollTo({top: 99999999999, left: 0, behavior: 'smooth'});
  }, [logContainer, messages]);

  if (!site) {
    return <div className="WPE__site__loading"><span>loading yo...</span></div>
  }

  const install = site.get('installs', List()).find(install => install.get('environment') === 'production') || Map();
  return (
    <div className="WPE__wrapper">
      <div className="WPE__site">
        <Link to="/wpe">&lt; Back to all sites</Link>
        <SiteData site={site} />
        <WPEActions runAction={runAction} scope={install.get('name')} runningAction={runningAction} />
      </div>
      <div className="WPE__log">
        <div className="WPE__log__inner">
          <header>
            <h2 className={'WPE__log__title'}>Action Log {runningAction && (
              <span>
                <span className="WPE__log__throbber fa fa-spinner fa-spin" />
                <span dangerouslySetInnerHTML={{ __html: `Running <em>"${runningAction}"</em> action...`}} />
              </span>
            )}</h2>
            <IconButton aria-label="clear log" size="small" onClick={() => clearLog()}>
              <NotInterestedIcon fontSize="small" />
            </IconButton>
          </header>
          <div id="log" className="WPE__log__messages" ref={logContainer}>
            {messages.map((message, i) => <LogMessage key={i} message={message} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WPESite;
