import React from 'react';
import { List } from 'immutable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SiteData = ({ site, select }) => {
  const install = site.get('installs', List()).find(i => i.get('environment') === 'production');

  return (
    <div className="WPE__site__data">
      {install ? (
        <TableContainer>
          <Table sx={{ '& .MuiTableHead-root .MuiTableCell-root': { fontWeight: '700'}, '& .MuiTableCell-root': { padding: '8px' } }}>
            <TableHead>
              <TableRow>
                <TableCell>Install Name</TableCell>
                <TableCell align="right">PHP Version</TableCell>
                <TableCell align="right">Group</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {site.get('installs', List()).map((install) => (
                <TableRow
                  key={install.get('name')}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{install.get('name')}</TableCell>
                  <TableCell align="right">{install.get('php_version')}</TableCell>
                  <TableCell align="right">{site.get('group_name')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>      
      ) : (
        <Paper><p>No Environments created for this site</p></Paper>
      )}
    </div>
  );
};

export default SiteData;
