import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import LineItem from '../../components/purchases/LineItem';
import * as actions from '../../actions';
import * as orders from '../../lib/order';

const mapStateToProps = ({ entities, ui }, ownProps) => {
  const { match: { params: { lineItemId } } } = ownProps;
  const lineItem = entities.line_items.get(lineItemId) || Immutable.Map();
  const userId = lineItem.getIn(['relationships', 'user', 'data', 'id']);
  const productId = lineItem.getIn(['relationships', 'product', 'data', 'id']);
  const refunds = entities.refunds
    .filter(r => r.getIn(['relationships', 'line_item', 'data', 'id']) === lineItemId);
  const orderId = lineItem.getIn(['relationships', 'order', 'data', 'id']);
  return {
    lineItemId,
    lineItem,
    user: entities.users.get(userId),
    product: entities.products.get(productId),
    refunds,
    order: entities.orders.get(orderId),
    notes: entities.notes,
    upgradeAuths: entities.upgrade_auths,
    ui,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUiValue: (path, value) => dispatch(actions.updateUiValue(path, value)),
  fetchLineItem: (id) => dispatch(actions.fetchLineItem(id)),
});

class StandaloneLineItem extends React.Component {
  componentDidMount() {
    const { fetchLineItem, lineItemId } = this.props;
    fetchLineItem(lineItemId);
  }

  render() {
    const {
      lineItem,
      lineItemId,
      order,
      product,
      refunds,
      notes,
      ui,
      updateUiValue,
      user,
      upgradeAuths,
    } = this.props;
    if (!lineItem || !product) {
      return null;
    }
    return (
      <div className="user-dashboard">
        <div className="line-item-list">
          <ul>
            <LineItem
              lineItem={lineItem}
              order={order}
              product={product}
              refunds={refunds}
              notes={notes}
              formUi={ui.getIn(['refundForms', lineItemId])}
              updateUiValue={updateUiValue}
              user={user}
              upgradeAuth={orders.upgradeAuth(lineItem, upgradeAuths)}
              sourceUpgradeAuth={orders.sourceUpgradeAuth(lineItem, upgradeAuths)}
            />
          </ul>
        </div>
      </div>
    );
  }
}

StandaloneLineItem.propTypes = {
  lineItemId: PropTypes.string.isRequired,
  lineItem: PropTypes.instanceOf(Immutable.Map),
  user: PropTypes.instanceOf(Immutable.Map),
  product: PropTypes.instanceOf(Immutable.Map),
  refunds: PropTypes.instanceOf(Immutable.Map).isRequired,
  order: PropTypes.instanceOf(Immutable.Map),
  upgradeAuths: PropTypes.instanceOf(Immutable.Map),
  notes: PropTypes.instanceOf(Immutable.Map).isRequired,
  ui: PropTypes.instanceOf(Immutable.Map).isRequired,
  updateUiValue: PropTypes.func.isRequired,
  fetchLineItem: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneLineItem);
