import { connect } from 'react-redux';
import Registrations from '../../components/registrations/Registrations';
import { fetchRegistrations, deleteRegistration } from '../../actions';

function itemRegistrations(registrations, lineItemId) {
  return registrations
    .filter(r => r.getIn(['relationships', 'line_item', 'data', 'id']) === lineItemId);
}

const mapStatetoProps = (state, ownProps) => ({
  lineItemId: ownProps.match.params.lineItemId,
  siteUrls: state.entities.site_urls,
  sites: state.entities.sites,
  registrations: itemRegistrations(state.entities.registrations, ownProps.match.params.lineItemId),
  product: state.entities.products.first(),
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: lineItemId => dispatch(fetchRegistrations(lineItemId)),
  deleteRegistration: (registrationId, lineItemId) => dispatch(deleteRegistration(registrationId, lineItemId)),
});

const RegistrationsContainer = connect(mapStatetoProps, mapDispatchToProps)(Registrations);
export default RegistrationsContainer;
