import { connect } from 'react-redux';
import Results from '../../components/users/UserSearchResults';

const mapStatetoProps = ({ entities, ui }) => ({
  users: entities.users,
  user_emails: entities.user_emails,
  ui,
});

const UserSearchResults = connect(mapStatetoProps)(Results);
export default UserSearchResults;
