import { Map, fromJS } from 'immutable';
import {
  receivingEntities,
  receivedEntities,
  updateWpeSites,
  updateHostedSites,
  updateHostingAccounts,
  updateWcReports,
  updateEntity,
  deleteEntity,
  addHostedSite,
  updateWcOrders,
  updateWcSubscriptions,
  receivedPartialEntities,
  updateDesignSales,
} from './entities';
import { updateUiValue } from './ui';
import ppFetch, { wpeFetch, wcFetch, downshiftFetch, prophotoFetch } from '../lib/fetch';
import * as constants from './constants';

// const fixture = require('../fixtures/subscriptions.json');

/**
 * Indicates a hosted sites action is being run
 *
 * @return {Object}
 */
 export function runningHostedSitesAction(action) {
  return {
    type: constants.RUNNING_HOSTED_SITES_ACTION,
    action,
  };
}

/**
 * Indicates a hosted sites action is finished running
 *
 * @return {Object}
 */
 export function completedHostedSitesAction() {
  return {
    type: constants.COMPLETED_HOSTED_SITES_ACTION,
  };
}

export function clearLogMessages(data) {
  return {
    type: constants.CLEAR_LOG,
  };
}

export function appendLogMessage(data) {
  const { status, mssg } = data;
  const cleanMessage = JSON.stringify(mssg).replace(/^"(.*)"$/, '$1')
  return {
    type: constants.APPEND_LOG_MESSAGE,
    messageType: status,
    message: cleanMessage,
  };
}

export function dismissLogMessages() {
  return {
    type: constants.DISMISS_LOG_MESSAGES,
  };
}

/**
 * Recursively fetch 
 * 
 * @param {number} fetchCount 
 * @param {object} hostedSites 
 * @returns Promise|Immutable.Map
 */
function fetchAllSites(dispatch, url = 'https://api.wpengineapi.com/v1/sites?limit=100', hostedSites = {}) {
  return wpeFetch(url)
  .then(response => response.json())
  .then(data => {
    data.results.forEach((site) => { hostedSites[site.id] = site; });
    const fetchedCount = Object.keys(hostedSites).length;
    dispatch(receivedPartialEntities('wpe_sites', (fetchedCount / data.count) * 100));
    if ( fetchedCount < data.count) {
      return fetchAllSites(dispatch, data.next, hostedSites);
    } else {
      return fromJS(hostedSites);
    }
  });
}

/**
 * Fetch all hosted sites
 *
 * @return {Function}
 */
 export function fetchSites() {
   return (dispatch) => {
    dispatch(receivedPartialEntities('wpe_sites', 1));
    fetchAllSites(dispatch)
      .then(hostedSites => dispatch(updateWpeSites(hostedSites)))
      .finally(() => dispatch(receivedEntities('wpe_sites')));
  };
}

/**
 * Fetch single hosted site
 *
 * @return {Function}
 */
 export function fetchSite(id) {
  return (dispatch) => {
   dispatch(receivingEntities('wpe_sites'));
   wpeFetch(`https://api.wpengineapi.com/v1/sites/${id}`)
    .then(response => response.json())
    .then(data => dispatch(addHostedSite(fromJS(data))))
    .finally(() => dispatch(receivedEntities('wpe_sites')));
 };
}

/**
 * Delete a WP Engine Site
 *
 * @return {Function}
 */
export function deleteWpeSite(id) {
  return async dispatch => {
    await wpeFetch(`https://api.wpengineapi.com/v1/sites/${id}`, {
      method: 'DELETE',
    });
    dispatch(deleteEntity(id, 'wpe_sites'))
  }
}

/**
 * Fetch all wpe hosting accounts
 *
 * @return {Function}
 */
 export function fetchAccounts() {
  return (dispatch) => {
    dispatch(receivingEntities('hosting_accounts'));
    wpeFetch('https://api.wpengineapi.com/v1/accounts')
      .then(response => response.json())
      .then(data => {
        const hostingAccounts = data.results.reduce((acc, acct) => acc.set(acct.name, acct.id), Map());
        dispatch(updateHostingAccounts(hostingAccounts));
      })
      .finally(() => dispatch(receivedEntities('hosting_accounts')));
  };
}

async function fetchWcSubscriptions(dispatch, page = 1, subs = []) {
  const res = await wpeFetch('/api/subscriptions');
  const data = await res.json();
  return data;
}

export function fetchWooCommerceSubscriptions(dispatch) {
  return async dispatch => {
    dispatch(receivedPartialEntities('wc_subscriptions', 1));
    try {
      const { date, subscriptions, isSyncing } = await fetchWcSubscriptions(dispatch);
      const mappedSubs = subscriptions.reduce((acc, sub) => ( acc.set(`${sub.id}`, fromJS(sub)) ), Map());
      dispatch(updateWcSubscriptions(mappedSubs));
      dispatch(updateUiValue(['subscriptionCacheDate'], date));
      dispatch(updateUiValue(['isSyncingSubscriptions'], isSyncing));

    } catch(error) {
      console.error(error);
    }
    dispatch(receivedEntities('wc_subscriptions'));
  }
}

export function connectUnconnectedWpeSite(wpUserId, wpeSite) {
  return async dispatch => {
    const res = await downshiftFetch(`${process.env.REACT_APP_DOWNSHIFT_API_URL}/hosted-sites`, {
      method: 'POST',
      body: JSON.stringify({
          wp_user_id: wpUserId,
          domain: wpeSite.get('installs').find(install => install.get('environment') === 'production').get('name'),
          hosted_site_id: wpeSite.get('id'),
          status: 'complete',
      })
    });
    const json = await res.json();
    dispatch(addHostedSite(fromJS(json.data)));
  }
}

async function fetchWcOrders(after, page = 1, orders = []) {
  const url = `https://pro.photo/wp-json/wc/v3/orders?per_page=100&page=${page}`;
  const response = await wcFetch(url);
  const json = await response.json();
  const collection = orders.concat(json);
  const totalPages = response.headers.get('X-WP-TotalPages');
  if (totalPages && totalPages > page) {
    return fetchWcOrders(after, page + 1, collection);
  } else {
    return collection;
  }
}

export function fetchWooCommerceOrders(after) {
  return async dispatch => {
    dispatch(receivedPartialEntities('wc_orders', 0));
    const orders = await fetchWcOrders(after);
    const mappedOrders = orders.reduce((acc, order) => ( acc.set(`${order.id}`, fromJS(order)) ), Map());
    dispatch(updateWcOrders(mappedOrders));
    dispatch(receivedEntities('wc_orders'));
  }
}

export async function createHostedSite(attributes) {
  // receiving entity action
  const create = await ppFetch('https://api.pro.photo/hosted-sites', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'hosted_sites',
        attributes,
      }
    })
  });
  await create.json();
  // add entity action
  // received entity action
}

export function fetchHostedSites() {
  return async dispatch => {
    dispatch(receivedPartialEntities('hosted_sites', 1));
    const res = await downshiftFetch(`${process.env.REACT_APP_DOWNSHIFT_API_URL}/hosted-sites`);
    const json = await res.json();
    const mappedHostedSites = json.data.reduce((acc, hs) => ( acc.set(`${hs.id}`, fromJS(hs)) ), Map());
    dispatch(updateHostedSites(mappedHostedSites));
    dispatch(receivedEntities('hosted_sites'));
  }
}

export function updateHostedSite(hostedSiteId, data) {
  return async dispatch => {
    const res = await downshiftFetch(`${process.env.REACT_APP_DOWNSHIFT_API_URL}/hosted-sites/${hostedSiteId}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    const json = await res.json();
    dispatch(updateEntity(hostedSiteId, 'hosted_sites', fromJS(json.data)));
  }
}

export function fetchDesignSales() {
  return async dispatch => {
    dispatch(receivingEntities('design_sales'));
    const res = await prophotoFetch(`${process.env.REACT_APP_PP_API_URL}/orders?product_category=design`);
    const json = await res.json();
    dispatch(updateDesignSales(fromJS(json)));
    dispatch(receivedEntities('design_sales'));
  }
}

async function getCurrentP8Version() {
  const res = await ppFetch('https://api.pro.photo/releases');
  const json = await res.json();
  const p8release = json.data.find(release => release.relationships.product.data.id === '71b11147-fb33-4747-95ae-eb333fda3e26');
  return p8release.attributes.latest;
}

export function wpeSiteAction(installName, action, args = {}) {
  return async (dispatch) => {
    dispatch(runningHostedSitesAction(action));

    if (action === 'prophoto') {
      args.currentP8Version = await getCurrentP8Version();
    }

    const res = await wpeFetch(`/api/action`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        installName,
        action,
        args,
      })
    });
    const data = await res.json();

    dispatch(appendLogMessage(data));
    dispatch(completedHostedSitesAction());
  };
}

async function runWpeAccountAction(i, sites, action, args = {}, dispatch) {
  const site  = sites[i];
  const prodInstall = site.installs.find(install => install.environment === 'production') || {};
  try {
    const res = await wpeFetch(`/api/action`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        installName: prodInstall.name,
        action,
        args,
      })
    });
    const data = await res.json();
    dispatch(appendLogMessage(data));
    i++
    if (i < sites.length) {
      return await runWpeAccountAction(i, sites, action, args, dispatch);
    } else {
      return { status: 'success' };
    }
  } catch(error) {
    return { status: 'error', error };
  }
}

export function wpeAccountAction(accountId, action, args = {}) {
  return async (dispatch, getState) => {
    dispatch(runningHostedSitesAction(action));

    const sites = getState().entities.wpe_sites.filter(hs => hs.getIn(['account', 'id'], '') === accountId).valueSeq().toJS();
    if (action === 'prophoto') {
      args.currentP8Version = await getCurrentP8Version();
    }

    const res = await runWpeAccountAction(0, sites, action, args, dispatch);

    if (res.status === 'error') {
      console.error(res.error);
    }
    dispatch(completedHostedSitesAction());
  };
}

export function fetchDashboardReports() {
  return async (dispatch) => {
    const reports = {};
    dispatch(receivingEntities('wc_reports'));
    const yearRes = await wcFetch('https://pro.photo/wp-json/wc/v3/reports/sales?period=year');
    const yearData = await yearRes.json();
    reports.year = yearData[0];
    dispatch(receivedPartialEntities('wc_reports', 25));
    const monthRes = await wcFetch('https://pro.photo/wp-json/wc/v3/reports/sales?period=month');
    const monthData = await monthRes.json();
    reports.month = monthData[0];
    dispatch(receivedPartialEntities('wc_reports', 50));
    const lastMonthRes = await wcFetch('https://pro.photo/wp-json/wc/v3/reports/sales?period=last_month');
    const lastMonthData = await lastMonthRes.json();
    reports.lastMonth = lastMonthData[0];
    dispatch(receivedPartialEntities('wc_reports', 75));
    const weekRes = await wcFetch('https://pro.photo/wp-json/wc/v3/reports/sales?period=week');
    const weekData = await weekRes.json();
    reports.week = weekData[0];
    dispatch(updateWcReports(fromJS(reports)));
    dispatch(receivedEntities('wc_reports'));
  }
}
