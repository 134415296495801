import React from 'react';
import ReactDOM from 'react-dom';
import Immutable from 'immutable';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { reducer as formReducer } from 'redux-form';
import reducers from './reducers';
import App from './App';
import UserSearch from './containers/users/UserSearch';
import Dashboard from './containers/users/DashboardContainer';
import Registrations from './containers/registrations/RegistrationsContainer';
import StandaloneLineItem from './containers/purchases/StandaloneLineItem';
import ReportsDashboard from './components/reports/ReportsDashboard';
import { DEFAULT_ENTITY_STATE } from './actions/entities';
import CreateSubscription from './components/subscriptions/CreateSubscriptionOrderForm';
import WPEDashboard from './containers/hosted-sites/WPEDashboardContainer';
import WPESite from './containers/hosted-sites/WPESiteContainer';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';

const initialState = {
  entities: {
    ...DEFAULT_ENTITY_STATE,
  },
  ui: Immutable.fromJS({
    fetching: {
      users: false,
      registrations: false,
      sales: false,
      wpe_sites: false,
      hosted_sites: false,
      wc_subscriptions: false,
      wc_orders: false,
      wc_reports: false,
      design_sales: false,
    },
    wpe: {
      runningAction: false,
      messageLog: {
        show: false,
        messages: [],
      },
    },
    refundForms: {},
    deleting: {},
    creating: {
      email: false
    },
    mergingUser: false,
    showingEmailList: false,
    report: '',
    subscriptionCacheDate: '',
    isSyncingSubscriptions: false,
  }),
};

const browserHistory = createBrowserHistory();
const createRootReducer = (history) => combineReducers({
  ...reducers,
  // router: connectRouter(history),
  form: formReducer,
});

const store = createStore(
  createRootReducer(browserHistory),
  initialState,
  applyMiddleware(
    // routerMiddleware(history),
    thunkMiddleware,
  ),
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/users/:userId" component={Dashboard} />
        <Route path="/users" component={UserSearch} />
        <Route path="/registrations/:lineItemId" component={Registrations} />
        <Route path="/line-items/:lineItemId" component={StandaloneLineItem} />
        <Route path="/subscriptions" component={CreateSubscription} />
        <Route path="/wpe/:siteId" component={WPESite} />
        <Route path="/wpe" component={WPEDashboard} />
        <Route path="/reports/:reportSlug" component={ReportsDashboard} />
        <Route path="/reports" component={ReportsDashboard} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
