import * as React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as actions from '../../actions/hosted-sites';
import ProgressBar from '../ProgressBar';
import { getSubscriptionData, getDesignSalesData } from '../../lib/reports';

const $ = (num, withCents = true) => {
  if (num !== 0 && !num) {
    return '$0';
  }

  const options = { style: 'currency', currency: 'USD' };
  if (!withCents) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  }
  return new Intl.NumberFormat('en-US', options).format(num);
};

const RevenueReport = ({
  subscriptions,
  orders,
  fetchWooCommerceSubscriptions,
  isFetchingSubscriptions,
  designSales,
  fetchDesignSales,
  isFetchingDesignSales,
}) => {
  React.useEffect(() => {
    if (subscriptions.size === 0 && isFetchingSubscriptions === false) {
      fetchWooCommerceSubscriptions();
    }
    if (designSales.size === 0 && isFetchingDesignSales === false) {
      fetchDesignSales();
    }
  }, [
    subscriptions,
    fetchWooCommerceSubscriptions,
    isFetchingSubscriptions,
    designSales,
    fetchDesignSales,
    isFetchingDesignSales,
  ]);

  if ((subscriptions.size === 0 || isFetchingSubscriptions !== false) || (designSales.size === 0 || isFetchingDesignSales !== false)) {
    return (
      <React.Fragment>
        {isFetchingSubscriptions !== false && ( 
          <ProgressBar text="Fetching Subscription Sales Data" progress={isFetchingSubscriptions === false ? 0 : isFetchingSubscriptions} />
        )}
        {isFetchingDesignSales !== false && (
          <ProgressBar text="Fetching Design Sales Data" progress={isFetchingDesignSales === false ? 0 : isFetchingDesignSales} />
        )}
      </React.Fragment>
    );
  }

  const subscriptionData = getSubscriptionData(subscriptions);
  const netSites = subscriptionData.getIn(['totals', 'netSites']);
  const grossRevenue = subscriptionData.get('costs').reduce((acc, count, cost) => (acc + (count * cost)), 0);
  const serverCount = Math.ceil(netSites / 100);
  const serverCost = 700 * 0.96;
  const netRevenue = grossRevenue - (serverCount * serverCost);
  const designSalesData = getDesignSalesData(designSales);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4, '& th': { fontWeight: '700' } }}>
      <Grid container spacing={3} mt={1}>
        <Grid item md={12} lg={6} >
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography component="h3" variant="h6" color="primary" gutterBottom>Site Revenue Breakdown</Typography>
            <Table size="small" sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Site Type</TableCell>
                  <TableCell>Site Count</TableCell>
                  <TableCell>$/Site</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell>Full ($39)</TableCell>
                    <TableCell>{subscriptionData.getIn(['costs', '39'])}</TableCell>
                    <TableCell>$39</TableCell>
                    <TableCell>{$(parseFloat(39 * subscriptionData.getIn(['costs', '39'])))}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Discount ($25)</TableCell>
                    <TableCell>{subscriptionData.getIn(['costs', '25'])}</TableCell>
                    <TableCell>$25</TableCell>
                    <TableCell>{$(parseFloat(25 * subscriptionData.getIn(['costs', '25'])))}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Second site ($19)</TableCell>
                    <TableCell>{subscriptionData.getIn(['costs', '19'])}</TableCell>
                    <TableCell>$19</TableCell>
                    <TableCell>{$(parseFloat(19 * subscriptionData.getIn(['costs', '19'])))}</TableCell>
                  </TableRow>
                  {subscriptionData.getIn(['costs', '49']) && (
                    <TableRow>
                      <TableCell>Old price ($49)</TableCell>
                      <TableCell>{subscriptionData.getIn(['costs', '49'])}</TableCell>
                      <TableCell>$49</TableCell>
                      <TableCell>{$(parseFloat(49 * subscriptionData.getIn(['costs', '49'])))}</TableCell>
                    </TableRow>
                  )}
               {subscriptionData.get('costs').filter((siteCount, cost) => !['39', '25', '19'].includes(cost)).map((siteCount, cost) => (
                  <TableRow key={cost}>
                    <TableCell>{`Special pricing ($${cost})`}</TableCell>
                    <TableCell>{siteCount}</TableCell>
                    <TableCell>${parseInt(cost)}</TableCell>
                    <TableCell>{$(parseFloat(cost * siteCount))}</TableCell>
                  </TableRow>
                )).valueSeq()}
                <TableRow>
                  <TableCell colSpan={4}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: '700' }}>Gross</TableCell>
                  <TableCell>{netSites}</TableCell>
                  <TableCell>{$(grossRevenue / netSites)}</TableCell>
                  <TableCell>{$(grossRevenue)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: '700' }}>Hosting Costs</TableCell>
                  <TableCell></TableCell>
                  <TableCell>$6.72</TableCell>
                  <TableCell sx={{ color: 'error.main'}}>-{$(serverCount * serverCost)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: '700' }}>Net</TableCell>
                  <TableCell>{netSites}</TableCell>
                  <TableCell>{$(netRevenue / netSites)}</TableCell>
                  <TableCell>{$(netRevenue)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item md={12} lg={6}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography component="h3" variant="h6" color="primary" gutterBottom>Design Sales by Month</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Designs Sold</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Revenue</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Commission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {designSalesData.map(month => {
                  return (
                    <TableRow key={month.date} sx={month.isTotals ? { backgroundColor: '#f8f8f8', '& td': { fontWeight: '700' } } : {}}>
                      <TableCell>{month.date}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{month.sales || 0}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{$(month.total)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{$(month.total * 0.3)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptions: state.entities.wc_subscriptions,
    isFetchingSubscriptions: state.ui.getIn(['fetching', 'wc_subscriptions']),
    designSales: state.entities.design_sales,
    isFetchingDesignSales: state.ui.getIn(['fetching', 'design_sales']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWooCommerceSubscriptions: () => dispatch(actions.fetchWooCommerceSubscriptions()),
    fetchDesignSales: () => dispatch(actions.fetchDesignSales()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenueReport);
