import { connect } from 'react-redux';
import Search from '../../components/users/UserSearch';
import { searchUsers } from '../../actions';

const mapStatetoProps = state => ({
  users: state.entities.users,
});

const mapDispatchToProps = (dispatch) => ({
  search: email => dispatch(searchUsers(email))
});

const UserSearch = connect(mapStatetoProps, mapDispatchToProps)(Search);
export default UserSearch;
