/**
 * Resource specs
 * A resource spec is an object mapping resource inclusion to resource type
 * and location within a json api response
 *
 * EXAMPLE:
 * const RESOURCE_SPEC = {
 *  users: {
 *    type: 'users',
 *    location: 'data',
 *  },
 *  'orders.line_items': {
 *    type: 'line_items',
 *    location: 'included',
 *  },
 *  'orders.line_items.refunds': {
 *    type: 'refunds',
 *    location: 'included',
 *  },
 *  'orders.line_items.product': {
 *    type: 'products',
 *    location: 'included',
 *  }
 * }
 */

/**
 * Returns an array of query params that support filtering JSON API requests
 *
 * @param {Object}
 * @return {Array}
 */
function filterQuery(filters) {
  const keys = Object.keys(filters);
  return keys
    .map(k => `filter[${k}]=${filters[k]}`);
}

/**
 * Given a resource spec, return a query param array supporting
 * JSON API included resources.
 *
 * @param {Object} resourceSpec
 * @return {Array}
 */
function included(resourceSpec) {
  const includedResources = Object.keys(resourceSpec)
    .filter(k => resourceSpec[k].location === 'included');

  if (includedResources.length === 0) {
    return [];
  }

  return [`include=${includedResources.join(',')}`];
}

/**
 * Return a resource collection url. A path can represent a collection
 * of resources, or a single resource identified by an id
 *
 * @param {String} path - a resource path without leading slash
 * @param {Object} resourceSpec - see above comment on resource specs
 * @param {Object} filters
 * @return {String}
 */
export function resourceUrl(path, resourceSpec = {}, filters = {}) {
  const host = process.env.REACT_APP_API_HOST;
  const query = filterQuery(filters).concat(included(resourceSpec));
  const base =  `${host}/${path}`;
  if (query.length === 0) {
    return base;
  }
  return `${base}?${query.join('&')}`;
}

/**
 * Maps an array of values to a query string
 * eg. `someKey[]=value1&someKey[]=value2`
 * 
 * @param {string} key
 * @param {array} array
 * @return {string}
 */
export function arrayToQuery(key, array) {
  return array.map(value => `${key}[]=${value}`).join('&');
}
