import { Map, List, fromJS } from 'immutable';
import { each } from 'underscore';
import * as constants from '../actions/constants';

export default function ui(state = Map(), action) {
  switch (action.type) {
    case (constants.RECEIVING_ENTITIES): {
      return state.setIn(['fetching', action.resourceType], true);
    }
    case (constants.RECEIVED_PARTIAL_ENTITIES): {
      return state.setIn(['fetching', action.resourceType], action.percentage);
    }
    case (constants.RECEIVED_ENTITIES): {
      return state.setIn(['fetching', action.resourceType], false);
    }
    case (constants.UPDATE_ENTITIES): {
      return state.setIn(['fetching', action.resourceType], false);
    }
    case (constants.UPDATE_ENTITY_BATCH): {
      const fetching = {};

      each(action.entities, entity => {
        fetching[entity.resourceType] = false;
      });

      return state.mergeIn(['fetching'], fetching);
    }
    case (constants.UPDATE_UI_VALUE): {
      const { path, value } = action;
      const newValue = fromJS(value);
      return state.setIn(path, newValue);
    }
    case (constants.SET_REPORT): {
      const { report } = action;
      return state.set('report', report);
    }
    case (constants.RUNNING_HOSTED_SITES_ACTION): {
      return state.setIn(['wpe', 'runningAction'], action.action).setIn(['wpe', 'messageLog', 'show'], true);
    }
    case (constants.COMPLETED_HOSTED_SITES_ACTION): {
      return state.setIn(['wpe', 'runningAction'], false);
    }
    case (constants.CLEAR_LOG): {
      return state.setIn(['wpe', 'messageLog', 'messages'], List());
    }
    case (constants.APPEND_LOG_MESSAGE): {
      const messages = state.getIn(['wpe', 'messageLog', 'messages']);
      const newMessage = fromJS({ messageType: action.messageType, text: action.message });
      return state.setIn(['wpe', 'messageLog'], Map({ show: true, messages: messages.push(newMessage) }));
    }
    case (constants.DISMISS_LOG_MESSAGES): {
      return state.setIn(['wpe', 'messageLog', 'show'], false);
    }
    default: {
      return state;
    }
  }
}
