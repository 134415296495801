const CREDENTIALS_KEY = 'bob_creds';
const WPE_CREDENTIALS_KEY = 'wpe_creds';

/**
 * @return {Object|null}
 */
export function getCredentials() {
  const creds = localStorage.getItem(CREDENTIALS_KEY);

  if (creds) {
    return JSON.parse(creds);
  }
  return null;
}

/**
 * @return {Object|null}
 */
 export function getWpeCredentials() {
  const creds = localStorage.getItem(WPE_CREDENTIALS_KEY);

  if (creds) {
    return JSON.parse(creds);
  }
  return null;
}

/**
 * @return {Object|null}
 */
 export function getWcCredentials() {
  return {
    clientId: process.env.REACT_APP_WC_API_KEY,
    clientSecret: process.env.REACT_APP_WC_API_SECRET,
  };
}

/**
 * @return {Object|null}
 */
 export function getDownshiftCredentials() {
  return {
    clientId: process.env.REACT_APP_DOWNSHIFT_API_KEY,
    clientSecret: process.env.REACT_APP_DOWNSHIFT_API_SECRET,
  };
}

/**
 * @return {Object|null}
 */
export function getProPhotoCredentials() {
  return {
    clientId: process.env.REACT_APP_PP_API_USER,
    clientSecret: process.env.REACT_APP_PP_API_PASSWORD,
  };
}

/**
 * @return {Object|null}
 */
export function getLegacyApiCredentials() {
  return {
    clientId: process.env.REACT_APP_LEGACY_API_ID,
    clientSecret: process.env.REACT_APP_LEGACY_API_SECRET,
  };
}

/**
 * @return {Boolean}
 */
export function credentialsStored() {
  return !! getCredentials();
}

/**
 * @return {Boolean}
 */
 export function WpeCredentialsStored() {
  return !! getWpeCredentials();
}

/**
 * @param {String} clientId
 * @param {String} clientSecret
 */
export function login(clientId, clientSecret) {
  const creds = {
    clientId,
    clientSecret,
  };
  localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(creds));
}

/**
 * @param {String} clientId
 * @param {String} clientSecret
 */
 export function WpeLogin(clientId, clientSecret) {
  const creds = {
    clientId,
    clientSecret,
  };
  localStorage.setItem(WPE_CREDENTIALS_KEY, JSON.stringify(creds));
}
