import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Refresh from '@mui/icons-material/Refresh';
import Nav from './ReportsDashboardNav';
import Dashboard from './Dashboard';
import SubscriptionsReport from './SubscriptionsReport';
import RevenueReport from './RevenueReport';
import ProblemReport from './ProblemReport';

const getContent = reportSlug => {
  switch(reportSlug) {
    case 'revenue': {
      return <RevenueReport />;
    }
    case 'subscriptions': {
      return <SubscriptionsReport />;
    }
    case 'problems': {
      return <ProblemReport />;
    }
    default: {
      return <Dashboard />
    }
  }
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme({
  typography: {
    fontSize: 16,
  },
});

const ReportsDashboard = ({
  reportSlug,
  subscriptionCacheDate,
  isSyncingSubscriptions,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Reports
            </Typography>
            <Typography variant="body2">
              {`Subscriptions last fetched: ${moment(subscriptionCacheDate).format('MMM D, h:mm a')}`}
            </Typography>
            <IconButton color="inherit" disabled={isSyncingSubscriptions}>
              <Refresh sx={{
                animation: isSyncingSubscriptions ? "spin 2s linear infinite" : "none",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotate(-360deg)",
                  },
                  "100%": {
                    transform: "rotate(0deg)",
                  },
                },
              }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Nav />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {getContent(reportSlug)}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = (state, { match: { params: { reportSlug } } }) => {
  return {
    reportSlug,
    subscriptionCacheDate: state.ui.get('subscriptionCacheDate'),
    isSyncingSubscriptions: state.ui.get('isSyncingSubscriptions'),
  };
};

export default connect(mapStateToProps)(ReportsDashboard);
