import React from 'react';
import moment from 'moment';

export default function LogMessage({ message }) {
  console.log('message', message);
  const time = moment().format('MMMM Do YYYY, h:mm:ss--SS a');
  const classes = ['WPE__log__message'];
  if (message.get('messageType') === 'error') {
    classes.push('WPE__log__message--error')
  }
  return (
    <section className={classes.join(' ')} key={time}>
      <code>{time}</code>
      {message.get('text').split("\\n").map((p, i) => <p key={i}>{p}</p>)}
    </section>
  );
}
