import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

function getNotes(refund, notes) {
  console.log('notes', notes.toJS());
  const note = notes.find(n => {
    return n
    .getIn(['relationships', 'refunds', 'data'])
    .some(refund => refund.id === refund.get('id'));
  });
  console.log('note', note);

  if (!note) {
    return null;
  }
  return note.getIn(['attributes', 'text']);
}

const RefundInfo = ({ refunds, notes }) => (
  <table className="table">
    <caption>Item Refunds</caption>
    <thead>
      <tr>
        <th>Amount</th>
        <th>Type</th>
        <th>Created</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
    { refunds.valueSeq().map(refund => (
      <tr key={refund.get('id')}>
        <td>${ refund.getIn(['attributes', 'amount']).toFixed(2) }</td>
        <td>{ refund.getIn(['attributes', 'type']) }</td>
        <td>{ refund.getIn(['attributes', 'created_at']) }</td>
        <td>{ getNotes(refund, notes) }</td>
      </tr>
    )) }
    </tbody>
  </table>
);

RefundInfo.propTypes = {
  refunds: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default RefundInfo;
