import * as constants from './constants';

/**
 * Update a UI property
 *
 * @param {String} path - specify a path through the UI state using dot notation
 * @param {Mixed} value - the new ui value
 * @return {Object}
 */
export function updateUiValue(path, value) {
  return {
    type: constants.UPDATE_UI_VALUE,
    path,
    value,
  };
}
