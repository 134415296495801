import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Registration from './Registration';

/**
 * Flattens a registration map for easier use in a component view
 *
 * @param {Immutable.Map} registration
 * @return {Immutable.Map}
 */
function flatten(registration, sites) {
  const siteId = registration.getIn(['relationships', 'site', 'data', 'id']);
  const site = sites.get(siteId);
  return registration
    .get('attributes')
    .set('id', registration.get('id'))
    .set('site_id', siteId)
    .set('site_updated_at', site.getIn(['attributes', 'updated_at']));
}

/**
 * Gets the most recent url for a registration
 *
 * @param {Immutable.Map} flattened - a flattened registration
 * @param {Immutable.Map} siteUrls - a map of site url resources
 * @return {String}
 */
function registrationUrl(flattened, siteUrls) {
  const path = ['relationships', 'site', 'data', 'id'];
  return siteUrls
    .filter(u => u.getIn(path) === flattened.get('site_id'))
    .sortBy(u => new Date(u.getIn(['attributes', 'updated_at'])))
    .reverse()
    .map(u => u.getIn(['attributes', 'url']))
    .first();
}

/**
 * Returns a collection of registration resources that have
 * been transformed for use in display
 *
 * @param {Immutable.Map} registrations
 * @param {Immutable.Map} sites
 * @param {Immutable.Map} siteUrls
 * @return {Immutable.Seq.Indexed}
 */
function createRegistrationSeq(registrations, sites, siteUrls) {
  return registrations
    .map(r => flatten(r, sites))
    .map(r => r.set('url', registrationUrl(r, siteUrls)))
    .map(r => r.set('old_urls', siteUrls.filter(u => u.getIn(['attributes', 'url']) !== r.get('url'))))
    .map(r => r.set('old_urls', r.get('old_urls').map(u => u.getIn(['attributes', 'url']))))
    .map(r => r.set('old_urls', r.get('old_urls').toList()))
    .valueSeq();
}

class Registrations extends React.Component {
  componentDidMount() {
    const { fetch, lineItemId } = this.props;
    fetch(lineItemId);
  }

  render() {
    const {
      registrations,
      product,
      siteUrls,
      lineItemId,
      sites,
      ui,
      deleteRegistration
    } = this.props;
    const registrationSeq = createRegistrationSeq(registrations, sites, siteUrls);

    return (
      <div className="registrations">
        <h1>Registrations</h1>
        <p>
          <strong>Line Item ID: {lineItemId}</strong>
        </p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>url</th>
              <th>product</th>
              <th>site updated</th>
              <th>registered</th>
              <th colSpan="2">old urls</th>
            </tr>
          </thead>
          <tbody>
          { registrationSeq.map(r =>
            <Registration
              key={r.get('id')}
              registration={r}
              product={product}
              deleting={ui.getIn(['deleting', r.get('id')])}
              deleteRegistration={() => deleteRegistration(r.get('id'), lineItemId)}
            />
          )}
          </tbody>
        </table>
      </div>
    );
  }
}

Registrations.propTypes = {
  lineItemId: PropTypes.string,
  siteUrls: PropTypes.instanceOf(Immutable.Map).isRequired,
  sites: PropTypes.instanceOf(Immutable.Map).isRequired,
  registrations: PropTypes.instanceOf(Immutable.Map).isRequired,
  product: PropTypes.instanceOf(Immutable.Map),
  ui: PropTypes.instanceOf(Immutable.Map).isRequired,
  deleteRegistration: PropTypes.func.isRequired,
};

export default Registrations;
