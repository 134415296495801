import { connect } from 'react-redux';
import LineItemList from '../../components/purchases/LineItemList';
import * as actions from '../../actions';
import { withRouter } from 'react-router';

function sortedLineItems(lineItems, userId) {
  return lineItems
    .filter(li => li.getIn(['relationships', 'user', 'data', 'id']) === userId)
    .sortBy(li => new Date(li.getIn(['attributes', 'created_at'])))
    .reverse();
}

const mapStateToProps = ({ entities, ui }, { match: { params } }) => ({
  lineItems: sortedLineItems(entities.line_items, params.userId),
  products: entities.products,
  refunds: entities.refunds,
  orders: entities.orders,
  notes: entities.notes,
  upgradeAuths: entities.upgrade_auths,
  lineItemsBlacklist: entities.blacklisted_line_items,
  ui,
});

const mapDispatchToProps = (dispatch) => ({
  updateUiValue: (path, value) => dispatch(actions.updateUiValue(path, value))
});

const LineItemsContainer = connect(mapStateToProps, mapDispatchToProps)(LineItemList);
export default withRouter(LineItemsContainer);
