import React from 'react';
import PropTypes from 'prop-types';
import './css/BlacklistToggle.css';

const BlacklistToggle = ({
  lineItemId,
  blacklistId,
  remove,
  add,
}) => {
  const classes = ['blacklist-toggle btn'];
  if (!blacklistId) {
    classes.push('btn-danger');
  } else {
    classes.push('btn-success');
  }
  return (
    <button
      className={classes.join(' ')}
      title="prevents auto-updating"
      onClick={() => blacklistId ? remove(blacklistId, lineItemId) : add(lineItemId)}
    >
      {blacklistId ? 'remove from blacklist' : 'blacklist'}
    </button>
  );
};

BlacklistToggle.propTypes = {
  lineItemId: PropTypes.string.isRequired,
  blacklistId: PropTypes.string.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default BlacklistToggle;
