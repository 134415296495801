import { updateEntityBatch, entityDescriptors } from './entities';
import fetch from '../lib/fetch';
import * as url from '../lib/url';
import { REMOVE_ENTITY } from './constants';

/**
 * Describes the response from the JSON API. Used
 * to update entity state
 */
const RESOURCE_SPEC = {
  line_items: {
    type: 'line_items',
    location: 'data',
  },
  order: {
    type: 'orders',
    location: 'included',
  },
  'order.user': {
    type: 'users',
    location: 'included',
  },
  upgrade_auth: {
    type: 'upgrade_auths',
    location: 'included',
  },
  refunds: {
    type: 'refunds',
    location: 'included',
  },
  product: {
    type: 'products',
    location: 'included',
  },
  'refunds.notes': {
    type: 'notes',
    location: 'included',
  },
  'order.upgrade_auths': {
    type: 'upgrade_auths',
    location: 'included',
  },
  'blacklisted_line_items': {
    type: 'blacklisted_line_items',
    location: 'included',
  },
};

/**
 * Fetch user by id
 *
 * @param  {String} id
 */
export function fetchLineItem(id) {
  return (dispatch) => {
    const itemUrl = url.resourceUrl(`line-items/${id}`, RESOURCE_SPEC);
    fetch(itemUrl)
      .then(response => response.json())
      .then(data => {
        const descriptors = entityDescriptors(data, RESOURCE_SPEC);
        dispatch(updateEntityBatch(descriptors));
      });
  };
}

/**
 * @param {string} id 
 * @param {string} lineItemId 
 */
export function removeBlacklistRecord(id, lineItemId) {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_HOST}/blacklist/${id}`;
    fetch(url, { method: 'DELETE', mode: 'cors' })
      .then((res) => {
        if (res.ok) {
          dispatch(fetchLineItem(lineItemId));
          dispatch({
            type: REMOVE_ENTITY,
            resourceType: 'blacklisted_line_items',
            entityId: id,
          });
        } else {
          console.error(res.statusText);
          alert(`failed to remove blacklist record.\n${res.statusText}`);
        }
      })
      .catch(e => {
        console.error(e);
        alert(`failed to remove blacklist record.\n${e.message}`);
      });
  };
}

/**
 * @param {string} lineItemId 
 */
export function createBlacklistRecord(lineItemId) {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_HOST}/blacklist`;
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        data: {
          type: 'blacklisted_line_items',
          attributes: {
            line_item_id: lineItemId,
          },
        },
      }),
    })
      .then(res => {
        if (res.ok) {
          dispatch(fetchLineItem(lineItemId));
        } else {
          console.error(res.statusText);
          alert(`failed to create blacklist record.\n${res.statusText}`);
        }
      })
      .catch(e => {
        console.error(e);
        alert(`failed to create blacklist record.\n${e.message}`);
      });
  };
}
