import { connect } from 'react-redux';
import BlacklistToggle from '../../components/purchases/BlacklistToggle';
import * as actions from '../../actions';

const mapDispatchToProps = (dispatch) => ({
  add: (lineItemId) => dispatch(actions.createBlacklistRecord(lineItemId)),
  remove: (id, lineItemId) => dispatch(actions.removeBlacklistRecord(id, lineItemId)),
});

export default connect(null, mapDispatchToProps)(BlacklistToggle);
