import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import UserSearchForm from './UserSearchForm';
import UserSearchResults from '../../containers/users/UserSearchResults';
import './css/Search.css';

class UserSearch extends React.Component {
  componentDidMount() {
    const { search } = this.props;

    const query = this.getSearchParams();
    if (Array.from(query).length) {
      search(query);
    }
  }

  componentDidUpdate() {
    if (this.props.users.size === 1) {
      const location = { pathname: `/users/${this.props.users.first().get('id')}` };
      this.props.history.push(location);
    }
  }

  getSearchParams() {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    return (
      <div className="user-search">
        <UserSearchResults />
        <UserSearchForm search={this.props.search} query={this.getSearchParams()} />
      </div>
    );
  }
};

UserSearch.propTypes = {
  search: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Immutable.Map),
  location: PropTypes.instanceOf(Object),
};

export default UserSearch;
