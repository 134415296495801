import { connect } from 'react-redux';
import Dashboard from '../../components/users/Dashboard';
import { searchUsers } from '../../actions';

const mapStatetoProps = state => state;

const mapDispatchToProps = (dispatch) => ({
  search: email => dispatch(searchUsers(email)),
});

const DashboardContainer = connect(mapStatetoProps, mapDispatchToProps)(Dashboard);
export default DashboardContainer;
