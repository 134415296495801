export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const UPDATE_ENTITY_BATCH = 'UPDATE_ENTITY_BATCH';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const ADD_ENTITY = 'ADD_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const RECEIVING_ENTITIES = 'RECEIVING_ENTITIES';
export const RECEIVED_ENTITIES = 'RECEIVED_ENTITIES';
export const RECEIVED_PARTIAL_ENTITIES = 'RECEIVED_PARTIAL_ENTITIES';
export const UPDATE_UI_VALUE = 'UPDATE_UI_VALUE';
export const REQUESTING_REFUND = 'REQUESTING_REFUND';
export const RECEIVING_REFUND = 'RECEIVING_REFUND';
export const TOGGLE_EMAIL_VISIBILITY = 'TOGGLE_EMAIL_VISIBILITY';
export const SET_REPORT = 'SET_REPORT';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';
export const RUNNING_HOSTED_SITES_ACTION = 'RUNNING_HOSTED_SITES_ACTION';
export const COMPLETED_HOSTED_SITES_ACTION = 'COMPLETED_HOSTED_SITES_ACTION';
export const APPEND_LOG_MESSAGE = 'APPEND_LOG_MESSAGE';
export const DISMISS_LOG_MESSAGES = 'DISMISS_LOG_MESSAGES';
export const CLEAR_LOG = 'CLEAR_LOG';
