import Immutable from 'immutable';

/**
 * A reviver function (see https://facebook.github.io/immutable-js/docs/#/fromJS)
 * that ensures a fromJS call uses only ordered maps and lists
 *
 * @param key
 * @param value
 */
function orderedMapsAndLists(key, value) {
  const isIndexed = Immutable.Iterable.isIndexed(value);
  return isIndexed ? value.toList() : value.toOrderedMap();
}

/**
 * Create an immutable map of entities
 *
 * @param {String} type - the json api type
 * @param {Object} data
 * @param {String} location - the location on the JSON API payload
 * @return {Immutable.Map}
 */
export function entityMap(type, data, location = "data") {
  const items = data[location];

  if (! items) {
    return Immutable.Map();
  }

  const resources = Array.isArray(items) ? items : [items];

  const keyMap = resources
    .filter(i => i.type === type)
    .reduce((prev, current) => {
      const resourceMap = prev;
      resourceMap[current.id] = current;
      return resourceMap;
    }, {});
  return Immutable.fromJS(keyMap, orderedMapsAndLists);
}
