import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { size } from 'underscore';
import Immutable from 'immutable';

class UserSearch extends React.Component {
  getClasses() {
    const classes = ['user-search-results'];

    if (this.props.ui.getIn(['fetching', 'users'])) {
      classes.push('is-fetching');
    }

    return classes.join(' ');
  }

  render() {
    if (!size(this.props.location.search)) {
      return null;
    }

    return (
      <div className={this.getClasses()}>
        <h2>Search Results</h2>
        <ul>
          <div className="loader">
            <span>Searching...</span>
            <i className="fa fa-spinner fa-spin" />
          </div>
          {this.props.users.valueSeq().map(user => {
            const userFirstname = user.getIn(['attributes', 'first_name']);
            const userLastname = user.getIn(['attributes', 'last_name']);
            const emailIds = user.getIn(['relationships', 'user_emails', 'data'], Immutable.List()).map(email => email.get('id'));
            const userRoute = `/users/${user.get('id')}`;

            return (
              <li key={user.get('id')} onClick={() => this.props.history.push(userRoute)}>
                <i className="user-icon fa fa-user" />
                <span className="user-name">
                  {`${userFirstname} ${userLastname}`}
                </span>
                <span className="user-emails">
                  {emailIds.map(id => (
                    this.props.user_emails.getIn([id, 'attributes', 'address'])
                  )).toJS().join(', ')}
                </span>
              </li>
            );
          }).toSeq()}
        </ul>
      </div>
    );
  }
}

UserSearch.propTypes = {
  location: PropTypes.object.isRequired,
  users: PropTypes.instanceOf(Immutable.Map).isRequired,
  user_emails: PropTypes.instanceOf(Immutable.Map).isRequired,
  ui: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default withRouter(UserSearch);
