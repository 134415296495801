import { fromJS } from 'immutable';
import { receivingEntities } from './entities';
import fetch from '../lib/fetch';
import * as url from '../lib/url';
import * as prodConstants from '../lib/product-constants';
import * as constants from '../actions/constants';

const products = [
  prodConstants.PROPHOTO_7,
  prodConstants.PROPHOTO_6,
  prodConstants.PROPHOTO_5,
  prodConstants.PROPHOTO_4,
  prodConstants.PROPHOTO_3,
  prodConstants.PROPHOTO_7_UPGRADE,
  prodConstants.PROPHOTO_6_UPGRADE,
  prodConstants.PROPHOTO_5_UPGRADE,
  prodConstants.PROPHOTO_4_UPGRADE,
  prodConstants.PROPHOTO_3_UPGRADE,
  prodConstants.PROPHOTO_7_DISCOUNTED_UPGRADE,
  prodConstants.PROPHOTO_6_DISCOUNTED_UPGRADE,
  prodConstants.PROPHOTO_5_DISCOUNTED_UPGRADE,
  prodConstants.PROPHOTO_4_DISCOUNTED_UPGRADE,
  prodConstants.PROPHOTO_3_DISCOUNTED_UPGRADE,
  prodConstants.PROPHOTO_5_WITH_INSTALL,
  prodConstants.PROPHOTO_4_WITH_INSTALL,
  prodConstants.PROPHOTO_3_WITH_INSTALL,
];

export function setReport(report) {
  return {
    type: constants.SET_REPORT,
    report,
  };
}

export function updateSales(sales) {
  return {
    type: constants.UPDATE_ENTITIES,
    resourceType: 'sales',
    entities: fromJS(sales),
  };
}

/**
 * Set current report and fetch sales data if not already fetching/fetched
 * 
 * @param {string} report 
 */
export function report(report) {
  return (dispatch, getState) => {
    dispatch(setReport(report));

    const noFetch = [
      'p7-demos',
      'p7-demos-conversions',
      'p7-demos-unconverted',
    ];

    if (!noFetch.includes(report)) {
      const state = getState();
      const { entities: { sales }, ui } = state;
      const fetching = ui.getIn(['fetching', 'sales']);

      if (sales.size === 0 && !fetching) {
        const query = url.arrayToQuery('products', products);
        const salesUrl = url.resourceUrl(`users/products?${query}`);
        dispatch(receivingEntities('sales'));
        fetch(salesUrl)
          .then(response => response.json())
          .then(data => {
            dispatch(updateSales(data));
          });
      }
    }
  }
}
