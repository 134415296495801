import { receivingEntities, updateEntityBatch, entityDescriptors } from './entities';
import { updateUiValue } from './ui';
import fetch from '../lib/fetch';
import * as url from '../lib/url';

const RESOURCE_SPEC = {
  registrations: {
    type: 'registrations',
    location: 'included',
  },
  product: {
    type: 'products',
    location: 'included',
  },
  'registrations.site': {
    type: 'sites',
    location: 'included',
  },
  'registrations.site.urls': {
    type: 'site_urls',
    location: 'included',
  },
};

/**
 * Deletes a registration and refetches the list of line item registrations
 *
 * @param {String} registrationId
 * @param {String} lineItemId
 * @return {Function}
 */
export function deleteRegistration(registrationId, lineItemId) {
  return (dispatch) => {
    dispatch(updateUiValue(['deleting', registrationId], true));
    const delUrl = url.resourceUrl(`registrations/${registrationId}`);
    fetch(delUrl, { method: 'DELETE', mode: 'cors' })
      .then(() => dispatch(updateUiValue(['deleting', registrationId], false)))
      .then(() => fetchRegistrations(lineItemId).call(null, dispatch));
  };
}

/**
 * Fetch registrations by line item id
 *
 * @param {String} lineItemId
 * @return {Function}
 */
export function fetchRegistrations(lineItemId) {
  return (dispatch) => {
    dispatch(receivingEntities('registrations'));
    const fetchUrl = url.resourceUrl(`line-items/${lineItemId}`, RESOURCE_SPEC);
    fetch(fetchUrl)
      .then(response => response.json())
      .then(data => {
        const descriptors = entityDescriptors(data, RESOURCE_SPEC);
        dispatch(updateEntityBatch(descriptors));
      });
  };
}
