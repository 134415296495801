import {
  getWpeCredentials,
  getWcCredentials,
  getDownshiftCredentials,
  getProPhotoCredentials,
  getLegacyApiCredentials,
} from './auth';

const Headers = window.Headers;
const globalFetch = window.fetch;

/**
 * Handles errors by rejecting a response if there are errors. Otherwise
 * the response is resolved normally
 *
 * @param {Response} response
 * @return {Promise}
 */
export function handleErrors(response) {
  if (!response.ok) {
    return response.json()
      .then(json => json.errors ? json.errors[0] : 'Something went wrong')
      .then(msg => Promise.reject(new Error(msg)));
  }
  return Promise.resolve(response);
}

export default function fetch(input, init = {}) {
  const { clientId, clientSecret } = getLegacyApiCredentials();
  const headers = init.headers || new Headers();

  headers.append('Authorization', `Bearer ${clientSecret}`);
  headers.append('X-Client', `${clientId}`);
  init.headers = headers;
  init.mode = 'cors';

  return globalFetch(input, init);
}

export function wpeFetch(input, init = {}) {
  const { clientId, clientSecret } = getWpeCredentials();
  const headers = init.headers || new Headers();

  const authString = btoa(`${clientId}:${clientSecret}`);
  headers.append('Authorization', `Basic ${authString}`);
  init.headers = headers;
  init.mode = 'cors';

  return globalFetch(input, init);
}

export function wcFetch(input, init = {}) {
  const { clientId, clientSecret } = getWcCredentials();
  const headers = init.headers || new Headers();

  const authString = btoa(`${clientId}:${clientSecret}`);
  headers.append('Authorization', `Basic ${authString}`);
  init.headers = headers;
  init.mode = 'cors';

  return globalFetch(input, init);
}

export function downshiftFetch(input, init = {}) {
  const { clientId, clientSecret } = getDownshiftCredentials();
  const headers = init.headers || new Headers();

  const authString = btoa(`${clientId}:${clientSecret}`);
  headers.append('Authorization', `Basic ${authString}`);
  headers.append('Content-Type', 'application/json');
  init.headers = headers;
  init.mode = 'cors';

  return globalFetch(input, init);
}

export function prophotoFetch(input, init = {}) {
  const { clientId, clientSecret } = getProPhotoCredentials();
  const headers = init.headers || new Headers();

  const authString = btoa(`${clientId}:${clientSecret}`);
  headers.append('Authorization', `Basic ${authString}`);
  headers.append('Content-Type', 'application/json');
  init.headers = headers;
  init.mode = 'cors';

  return globalFetch(input, init);
}
