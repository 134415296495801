import React from 'react';
import PropTypes from 'prop-types';

function onSubmit(search) {
}

class UserSearchForm extends React.Component {
  render() {
    const { search, query } = this.props;
    return (
      <div className="user-search-form">
        <h2>User Search Form</h2>
        <form
          method="GET"
          action=""
          id="search-form"
          className="dashboard__user-form"
          onSubmit={onSubmit(search)}
        >
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="user@email.com"
              defaultValue={query.get('email')}
            />
          </div>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              className="form-control"
              placeholder="Joe"
              defaultValue={query.get('first_name')}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              className="form-control"
              placeholder="Smith"
              defaultValue={query.get('last_name')}
            />
          </div>
          <div className="form-group">
            <label>URL</label>
            <input
              type="text"
              name="url"
              className="form-control"
              placeholder="lol.com"
              defaultValue={query.get('url')}
            />
          </div>
          <button className="btn btn-primary" type="submit">search</button>
        </form>
      </div>
    );
  }
}

UserSearchForm.propTypes = {
  search: PropTypes.func.isRequired,
  query: PropTypes.instanceOf(URLSearchParams).isRequired,
};

export default UserSearchForm;
