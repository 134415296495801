import * as React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SiteConnectionButton from './SiteConnectionButton';
import * as actions from '../../actions/hosted-sites';
import ProgressBar from '../ProgressBar';
import { getSiteCountFromSub } from '../../lib/reports';

const getProblemSubscriptionData = ({ subscriptions, hostedSites, wpeSites }) => {
  return subscriptions.reduce((data, sub) => {
    const customerId = sub.get('customer_id');
    const subStatus = sub.get('status');
    const subscriptionHostedSites = hostedSites.filter(hs => {
      return hs.getIn(['attributes', 'status']) !== 'cancelled' && hs.getIn(['attributes', 'wp_user_id']) == customerId;
    });
    const subscriptionWpeSites = subscriptionHostedSites
      .map(hs => {
        const wpeSite = wpeSites.get(hs.getIn(['attributes', 'hosted_site_id']));
        if (wpeSite) {
          return wpeSite.set('hostedSiteId', hs.get('id'));
        }
        return undefined;
      })
      .filter(wpeSite => wpeSite !== undefined);
    const subWithWpeSites = sub.set('sites', subscriptionWpeSites);
    switch(subStatus) {
      case 'active': {
        const expectedSiteCount = getSiteCountFromSub(sub);
        if (expectedSiteCount !== subscriptionWpeSites.size) {
          return data.update('activeSubsWithMismatchedSites', List(), current => current.push(subWithWpeSites));
        }
        break;
      }
      case 'cancelled': {
        if (subscriptions.find(otherSub => otherSub.get('customer_id') === customerId && otherSub.get('status') !== 'cancelled')) {
          return data;
        }
        if (subscriptionWpeSites.size > 0) {
          return data.update('cancelledSubscriptionsWithActiveSites', List(), current => current.push(subWithWpeSites));
        }
        break;
      }
      case 'on-hold': {
        return data.update('onHoldSubscriptions', List(), current => current.push(subWithWpeSites));
      }
      default: {
        /* ¯\_(ツ)_/¯ */
      }
    }
    return data;
  }, Map());
}

const getUncancelledHostedSites = ({ hostedSites, wpeSites, subscriptions }) => {
  return hostedSites.reduce((acc, hs) => {
    const wpeSiteId = hs.getIn(['attributes', 'hosted_site_id']);
    const userId = hs.getIn(['attributes', 'wp_user_id']);
    const hsStatus = hs.getIn(['attributes', 'status']);
    const wpeSite = wpeSites.get(wpeSiteId);

    if (!wpeSiteId || wpeSite || hsStatus === 'cancelled') {
      return acc;
    }

    let subscription = subscriptions.filter(sub => sub.get('customer_id') == userId);
    if (subscription && subscription.size > 1) {
      let current = subscription.filter(sub => sub.get('status') === 'active');
      if (!current) {
        current = subscription.filter(sub => sub.get('status') !== 'cancelled');
      }
      if (current) {
        subscription = current;
      }
    }
    return acc.push(Map({ hostedSite: hs, subscription: subscription.first() }));
  }, List());
}

const getUnconnectedWpeSites = (hostedSites, wpeSites) => {
  const hostedSiteIds = hostedSites.map(hs => hs.getIn(['attributes', 'hosted_site_id'])).valueSeq();
  return wpeSites
    .filter(site => !hostedSiteIds.includes(site.get('id')))
    .valueSeq()
    .sortBy(site => site.get('name'));
}

const customerNameFromSubscription = sub => {
  if (!sub) {
    return 'no subscription';
  }
  return `${sub.getIn(['billing', 'first_name'])} ${sub.getIn(['billing', 'last_name'])}`;
}

const getInstallLink = (site) => {
  const productionInstall = site.get('installs').find(install => install.get('environment') === 'production');
  if (!productionInstall || !productionInstall.get('cname')) {
    return 'no installs';
  }

  const cname = productionInstall.get('cname');
  return <a href={`https://${cname}`}>{cname}</a>;
}

const subscriptionLink = (sub, text) => (
  <a href={`https://pro.photo/wp-admin/post.php?post=${sub.get('id')}&action=edit`} target="__blank">{text}</a>
);

const wpeSiteDashboardLink = (wpeSite, text) => {
  const install = wpeSite
    .get('installs', List())
    .find(i => i.get('environment') === 'production');

  if (!install) {
    return text;
  }

  return <a href={`https://my.wpengine.com/installs/${install.get('name', '')}`} target="__blank">{text}</a>
};

const getDaysOverdueColor = (daysOverdue) => {
  if (daysOverdue > 28) {
    return 'error.main';
  } else if (daysOverdue > 7) {
    return 'warning.main';
  } else {
    return 'text.main'; 
  }
}

const UnconnectedWpeSites = ({
  data,
}) => {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" variant="h6" color="primary" gutterBottom>Unconnected WPE Sites</Typography>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>Site Name</TableCell>
            <TableCell>Site Url</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(site => (
            <TableRow key={site.get('id')}>
              <TableCell>{wpeSiteDashboardLink(site, site.get('name'))}</TableCell>
              <TableCell>{getInstallLink(site)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const SubsWithMismatchedSites = ({
  data,
  unconnectedWpeSites,
  connectUnconnectedWpeSite,
}) => {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" variant="h6" color="primary" gutterBottom>Mismatched Site Counts</Typography>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Expected Site Count</TableCell>
            <TableCell>Actual Sites Count</TableCell>
            <TableCell>WPE Sites</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(sub => {
            const subSiteCount = getSiteCountFromSub(sub);
            const siteList = sub
              .get('sites')
              .sort((a, b) => parseInt(a.get('name').split('-')[2] || 0, 10) > parseInt(b.get('name').split('-')[2] || 0, 10) ? 1 : -1)
              .valueSeq();
            const status = subSiteCount > siteList.size ? 'overpaying' : 'underpaying';
            return (
              <TableRow key={sub.get('id')}>
                <TableCell>{subscriptionLink(sub, customerNameFromSubscription(sub))}</TableCell>
                <TableCell><Typography component="h3">{subSiteCount}</Typography></TableCell>
                <TableCell>
                  <Typography component="h3" sx={{ color: status === 'underpaying' ? 'error.main' : 'text.main'}}>{siteList.size}</Typography>
                </TableCell>
                <TableCell>{siteList.map(wpeSite => (
                  <div key={wpeSite.get('id')}>{wpeSiteDashboardLink(wpeSite, wpeSite.get('name'))}</div>
                ))}</TableCell>
                {status === 'overpaying' && (
                  <TableCell>
                    <SiteConnectionButton subscription={sub} unconnectedWpeSites={unconnectedWpeSites} connectUnconnectedWpeSite={connectUnconnectedWpeSite} />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

const OnHoldSubscriptions = ({
  data,
}) => {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" variant="h6" color="primary" gutterBottom>On Hold Subscriptions</Typography>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Failed Payment Date</TableCell>
            <TableCell>Sites</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.sortBy(sub => sub.get('last_payment_date_gmt')).map(sub => {
            const todayTimestamp = moment().unix();
            const lastPaymentTimestamp = moment(sub.get('last_payment_date_gmt')).unix();
            const daysOverdue = Math.floor((todayTimestamp - lastPaymentTimestamp) / (60 * 60 * 24));
            return (
              <TableRow key={sub.get('id')}>
                <TableCell>{subscriptionLink(sub, customerNameFromSubscription(sub))}</TableCell>
                <TableCell>{moment(sub.get('date_created_gmt')).format('MM/DD/YYYY')}</TableCell>
                <TableCell>
                  <span>{moment(sub.get('last_payment_date_gmt')).format('MM/DD/YYYY')}</span>
                  <Typography component="span" sx={{ marginLeft: '5px', color: getDaysOverdueColor(daysOverdue) }}>
                    {`(${daysOverdue} days)`}
                  </Typography>
                </TableCell>
                <TableCell>{sub.get('sites').map(wpeSite => (
                  <div key={wpeSite.get('id')}>{wpeSiteDashboardLink(wpeSite, wpeSite.get('name'))}</div>
                )).valueSeq()}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

const UncancelledHostedSites = ({
  data,
  updateHostedSite,
}) => {
  const [isBeingCancelled, setIsBeingCancelled] = React.useState([]);
  const setHostedSiteAsCancelled = async (hsId) => {
    setIsBeingCancelled(isBeingCancelled.concat(hsId));
    updateHostedSite(hsId, { status: 'cancelled' })
      .then(() => setIsBeingCancelled(isBeingCancelled.splice(isBeingCancelled.indexOf(hsId), 1)));
  };

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" variant="h6" color="primary" gutterBottom>Potential Uncancelled Hosted Sites</Typography>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Hosted Site Status</TableCell>
            <TableCell>Subscription Status</TableCell>
            <TableCell>Cancel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => {
            const hsId = item.getIn(['hostedSite', 'id']);
            return (
              <TableRow key={hsId}>
                <TableCell>{customerNameFromSubscription(item.get('subscription'))}</TableCell>
                <Tooltip title={item.getIn(['hostedSite', 'attributes', 'domain'])} followCursor>
                  <TableCell>{item.getIn(['hostedSite', 'attributes', 'status'])}</TableCell>
                </Tooltip>
                <TableCell>{item.getIn(['subscription', 'status']) || 'missing'}</TableCell>
                <TableCell>
                  {isBeingCancelled.includes(hsId) ? (
                    <CircularProgress color="error" />
                  ) : (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => setHostedSiteAsCancelled(hsId)}
                      color="error"
                    >
                      Cancel Hosted Site
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

const CancelledSubscriptionsWithActiveSites = ({
  data,
  updateHostedSite,
  deleteWpeSite,
}) => {
  const [isBeingDeleted, setIsBeingDeleted] = React.useState([]);
  const deleteSite = async (wpeSite) => {
    if (!window.confirm(`Are you sure?  This will permanently delete the WP Engine site "${wpeSite.get('name')}" and all it's environments!`)) {
      return;
    }
    const hsId = wpeSite.get('hostedSiteId');
    setIsBeingDeleted(isBeingDeleted.concat(hsId));
    await updateHostedSite(hsId, { status: 'cancelled' });
    await deleteWpeSite(wpeSite.get('id'));
    setIsBeingDeleted(isBeingDeleted.splice(isBeingDeleted.indexOf(hsId), 1));
  };

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" variant="h6" color="primary" gutterBottom>Cancelled Subscriptions With Active Sites</Typography>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Cancellation Date</TableCell>
            <TableCell>Cancel Site</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(sub => {
            return (
              <TableRow key={sub.get('id')}>
                <TableCell>{subscriptionLink(sub, customerNameFromSubscription(sub))}</TableCell>
                <TableCell>{moment(sub.get('date_created_gmt')).format('MMM DD, YYYY')}</TableCell>
                <TableCell>{moment(sub.get('cancelled_date_gmt')).format('MMM DD, YYYY')}</TableCell>
                <TableCell sx={{ '& .delete-site': { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}}>
                  {sub.get('sites').map(wpeSite => (
                    <div className="delete-site" key={wpeSite.get('id')}>
                      <span>{wpeSiteDashboardLink(wpeSite, wpeSite.get('name'))}</span>
                      <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => deleteSite(wpeSite)}>
                          Cancel + Delete
                      </Button>
                    </div>
                  )).valueSeq()}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

const ProblemReport = ({
  hostedSites,
  wpeSites,
  subscriptions,
  isFetchingHostedSites,
  isFetchingWpeSites,
  isFetchingSubscriptions,
  fetchHostedSites,
  fetchWpeSites,
  fetchWooCommerceSubscriptions,
  updateHostedSite,
  deleteWpeSite,
  connectUnconnectedWpeSite,
}) => {
  React.useEffect(() => {
    if (subscriptions.size === 0 && isFetchingSubscriptions === false) {
      fetchWooCommerceSubscriptions();
    }
    if (wpeSites.size === 0 && isFetchingWpeSites === false) {
      fetchWpeSites();
    }
    if (hostedSites.size === 0 && isFetchingHostedSites === false) {
      fetchHostedSites();
    }
  }, [
    hostedSites,
    wpeSites,
    subscriptions,
    isFetchingHostedSites,
    isFetchingWpeSites,
    isFetchingSubscriptions,
    fetchHostedSites,
    fetchWpeSites,
    fetchWooCommerceSubscriptions,
  ]);

  if (isFetchingWpeSites !== false || isFetchingHostedSites !== false || isFetchingSubscriptions !== false) {
    return (
      <React.Fragment>
        {isFetchingWpeSites !== false && ( 
          <ProgressBar text="Fetching WPE Sites" progress={isFetchingWpeSites === false ? 0 : isFetchingWpeSites} />
        )}
        {isFetchingHostedSites !== false && (
          <ProgressBar text="Fetching Hosted Sites" progress={isFetchingHostedSites === false ? 0 : isFetchingHostedSites} />
        )}
        {isFetchingSubscriptions !== false && (
          <ProgressBar text="Fetching Subscriptions" progress={isFetchingSubscriptions === false ? 0 : isFetchingSubscriptions} />
        )}
      </React.Fragment>
    );
  }

  const problemSubscriptionData = getProblemSubscriptionData({ subscriptions, hostedSites, wpeSites} );
  const uncancelledHostedSites = getUncancelledHostedSites({ hostedSites, wpeSites, subscriptions });
  const unconnectedWpeSites = getUnconnectedWpeSites(hostedSites, wpeSites);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4, '& th': { fontWeight: '700' } }}>
      <Grid container spacing={3} mt={1}>
        <Grid item>
          {problemSubscriptionData.has('cancelledSubscriptionsWithActiveSites') && (
            <CancelledSubscriptionsWithActiveSites
              data={problemSubscriptionData.get('cancelledSubscriptionsWithActiveSites')}
              deleteWpeSite={deleteWpeSite}
              updateHostedSite={updateHostedSite}
            />
          )}
        </Grid>
        <Grid item>
          {problemSubscriptionData.has('onHoldSubscriptions') && (
            <OnHoldSubscriptions data={problemSubscriptionData.get('onHoldSubscriptions')} />
          )}
        </Grid>
        <Grid item>
          {problemSubscriptionData.has('activeSubsWithMismatchedSites') && (
            <SubsWithMismatchedSites data={problemSubscriptionData.get('activeSubsWithMismatchedSites')} unconnectedWpeSites={unconnectedWpeSites} connectUnconnectedWpeSite={connectUnconnectedWpeSite} />
          )}
        </Grid>
        <Grid item>
          {unconnectedWpeSites.size && (
            <UnconnectedWpeSites data={unconnectedWpeSites} />
          )}
        </Grid>
        <Grid item>
          {uncancelledHostedSites.size && (
            <UncancelledHostedSites data={uncancelledHostedSites} updateHostedSite={updateHostedSite} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    hostedSites: state.entities.hosted_sites,
    isFetchingHostedSites: state.ui.getIn(['fetching', 'hosted_sites']),
    wpeSites: state.entities.wpe_sites,
    isFetchingWpeSites: state.ui.getIn(['fetching', 'wpe_sites']),
    subscriptions: state.entities.wc_subscriptions,
    isFetchingSubscriptions: state.ui.getIn(['fetching', 'wc_subscriptions']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHostedSites: () => dispatch(actions.fetchHostedSites()),
    fetchWpeSites: () => dispatch(actions.fetchSites()),
    fetchWooCommerceSubscriptions: () => dispatch(actions.fetchWooCommerceSubscriptions()),
    updateHostedSite: (hostedSiteId, data) => dispatch(actions.updateHostedSite(hostedSiteId, data)),
    deleteWpeSite: wpeSiteId => dispatch(actions.deleteWpeSite(wpeSiteId)),
    connectUnconnectedWpeSite: (wpUserId, wpeSite) => dispatch(actions.connectUnconnectedWpeSite(wpUserId, wpeSite)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemReport);
