import React from 'react';
import { withRouter } from 'react-router';
import fetch from '../../lib/fetch';
import * as prods from '../../lib/product-constants';
import './css/Form.css';

async function createSubscription(subscription) {
  const url = `${process.env.REACT_APP_API_HOST}/subscriptions`;
  const result = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      data: {
        type: 'orders',
        attributes: {
          total: subscription.total,
          tax: subscription.tax,
          user_email: subscription.email,
          address: {
            postal_code: subscription.zip,
            city: subscription.city,
            street: subscription.street,
            state: subscription.state,
          },
        },
        relationships: {
          products: {
            data: subscription.products.map(p => ({
              type: 'products',
              ...p,
            })),
          },
        },
      },
    }),
  });
  if (!result.ok) {
    throw new Error(result.statusText);
  }
  const order = await result.json();
  return order;
}

class CreateSubscriptionOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      error: '',
      email: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      cost: 20,
      tax: 0,
      total: 0,
    };
  }

  async onSubmit(e) {
    e.preventDefault();
    const {
      email,
      street,
      city,
      state,
      zip,
      cost,
      tax,
    } = this.state;
    const { router } = this.props;
    this.setState({ submitting: true, error: '' });
    try {
      const { data } = await createSubscription({
        email,
        street,
        city,
        state,
        zip,
        tax,
        total: this.getTotal(),
        products: [{
          id: prods.PROPHOTO_7,
          cost,
        }],
      });
      router.push(`/users/${data.relationships.user.data.id}`);
    } catch (e) {
      this.setState({ submitting: false, error: [`failed to create user: ${e.message}`] });
    }
  }

  createUpdater(name) {
    return ({ currentTarget }) => {
      const castToFloat = ['cost', 'tax', 'total'];
      if (castToFloat.includes(name)) {
        this.setState({ [name]: parseFloat(currentTarget.value) });
      } else {
        this.setState({ [name]: currentTarget.value });
      }
    };
  }

  getTotal() {
    const { cost, tax, total } = this.state;
    if (total) {
      return total;
    }
    if (cost || tax) {
      return cost + tax;
    }
    return 0;
  }

  render() {
    const {
      email,
      street,
      city,
      state,
      zip,
      cost,
      tax,
    } = this.state;
    return (
      <div className="create-subs-order-form">
        <h2>Create P7 Subscription Form</h2>
        <form
          method="POST"
          className="dashboard__user-form"
          onSubmit={this.onSubmit.bind(this)}
        >
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              className="form-control"
              placeholder="user@email.com"
              value={email}
              onChange={this.createUpdater('email')}
            />
          </div>
          <div className="form-group">
            <label>Street</label>
            <input
              type="text"
              className="form-control"
              placeholder="123 Sesame St."
              value={street}
              onChange={this.createUpdater('street')}
            />
          </div>
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              className="form-control"
              placeholder="Holland"
              value={city}
              onChange={this.createUpdater('city')}
            />
          </div>
          <div className="form-group">
            <label>State</label>
            <input
              type="text"
              className="form-control"
              placeholder="MI"
              value={state}
              onChange={this.createUpdater('state')}
            />
          </div>
          <div className="form-group">
            <label>Postal Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="12312"
              value={zip}
              onChange={this.createUpdater('zip')}
            />
          </div>
          <div className="form-group">
            <label>Cost</label>
            <input
              type="number"
              min={0}
              step={0.01}
              className="form-control"
              placeholder="20"
              value={cost}
              onChange={this.createUpdater('cost')}
            />
          </div>
          <div className="form-group">
            <label>Tax</label>
            <input
              type="number"
              min={0}
              step={0.01}
              className="form-control"
              placeholder="0"
              value={tax}
              onChange={this.createUpdater('tax')}
            />
          </div>
          <div className="form-group">
            <label>Total</label>
            <input
              type="number"
              min={0}
              step={0.01}
              className="form-control"
              placeholder="0"
              value={this.getTotal()}
              onChange={this.createUpdater('total')}
            />
          </div>
          <button className="btn btn-primary" type="submit">submit</button>
        </form>
      </div>
    );
  }
}

export default withRouter(CreateSubscriptionOrderForm);
