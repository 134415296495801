import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Map, List } from 'immutable';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import WPEActions from './WPEActions';
import SiteData from './SiteData';
import LogMessage from './LogMessage';
import './css/WPE-dashboard.css';

const DashboardSite = ({ site, hostingAccounts, noAccountSelected }) => {
  const accountName = hostingAccounts.reduce((acc, id, name) => {
    if (site.getIn(['account', 'id']) === id) {
      return name;
    }
    return acc;
  }, '');
  const prodInstall = site.get('installs', List([])).find(install => install.get('environment') === 'production');
  const installName = (prodInstall || Map()).get('name');

  return (
    <Paper className="WPEDashboard__BySite__site">
      {noAccountSelected && accountName && <div className="WPEDashboard__BySite__account"><em>{accountName}</em></div>}
      <div className="WPEDashboard__BySite__site__header">
        <Link to={`wpe/${site.get('id')}`}>
          <h4>{site.get('name')}</h4>
        </Link>
        <div className="WPEDashboard__BySite__site__header__links">
          <a href={`https://my.wpengine.com/installs/${installName}`} target="_blank" rel="noopener noreferrer">WPE Dashboard <OpenInNewIcon /></a>
          <a href={`https://my.wpengine.com/installs/${installName}/launch_wp_admin`} target="_blank" rel="noopener noreferrer">WP-Admin <OpenInNewIcon /></a>
        </div>
      </div>
      <SiteData site={site} />
    </Paper>
  );
};

const WPE = ({
  sites,
  hostingAccounts,
  runningAction,
  messages,
  isLogOpen,
  clearLog,
  fetchHostedSites,
  fetchHostingAccounts,
  runAccountAction,
  dismissLog,
  isFetchingHostedSites,
  isFetchingHostingAccounts,
}) => {
  const [selectedAccount, selectAccount] = useState('');
  const [search, setSearch] = useState('');
  const logContainer = useRef(null);

  useEffect(() => {
    if (!sites.size) {
      fetchHostedSites();
    }
  }, [fetchHostedSites, sites]);

  useEffect(() => {
    if (!hostingAccounts.size) {
      fetchHostingAccounts();
    }
  }, [fetchHostingAccounts, hostingAccounts]);

  useEffect(() => {
    if (!logContainer.current) {
      return;
    }
    logContainer.current.scrollTo({top: 99999999999, left: 0, behavior: 'smooth'});
  }, [logContainer, messages]);

  function getConfirmMessage(action, scope, hostingAccounts) {
    const accountName = hostingAccounts.reduce((acc, id, name) => {
      if (scope === id) {
        return name;
      }
      return acc;
    }, '');
    return `Are you sure you want to run action "${action}" against all sites on the "${accountName}" account?`;
  }

  const runAction = (scope, action) => {
    if (window.confirm(getConfirmMessage(action, scope, hostingAccounts))) {
      return runAccountAction(scope, action);
    }
  }

  const visibleSites = () => {
    if (!selectedAccount && !search) {
      return sites;
    }
    const inSelectedAccount = site => !selectedAccount || site.getIn(['account', 'id']) === selectedAccount;
    const siteNameContainsSerchTerm = site => site.get('name').toLowerCase().includes(search.toLowerCase());
    const installNameContainsSerchTerm = site => (site.get('installs', List()).first() || Map()).get('name', '').toLowerCase().includes(search.toLowerCase());
    return sites.filter(site => inSelectedAccount(site) && (siteNameContainsSerchTerm(site) || installNameContainsSerchTerm(site)));
  }

  const isLoading = isFetchingHostedSites;

  return (
    <div className="WPEDashboard">
      <div>
        <h2>Account-wide Actions</h2>
        <section className="WPEDashboard__ByAccount">
          <select onChange={(e) => selectAccount(e.target.value)} value={selectedAccount}>
            <option value="">Select an account</option>
            {hostingAccounts.map((id, name) => (
              <option key={id} value={id}>{name}</option>
            )).valueSeq()}
          </select>
          {selectedAccount && (
            <WPEActions
              runAction={runAction}
              scope={selectedAccount}
              runningAction={runningAction}
            />
          )}
        </section>
      </div>
      <div>
        <h2>Individual Sites</h2>
        <div className="WPEDashboard__search">
          <span className="fa fa-search" />
          <input type="text" value={search} onChange={e => setSearch(e.target.value)} />
        </div>
        <section className="WPEDashboard__BySite">
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress /></Box>
          ) : (
            visibleSites().map(site => (
              <DashboardSite
                key={site.get('id')}
                site={site}
                hostingAccounts={hostingAccounts}
                noAccountSelected={selectedAccount === ''}
              />
            )).valueSeq()
          )}
        </section>
      </div>
      {isLogOpen && (
        <div className="WPEDashboard__Log__Wrap">
          <div className="WPEDashboard__Log__Overlay" />
          <div className="WPEDashboard__Log">
            <header>
              <h2 className="WpeDashboard__Log__Title WpeDashboard__Log__Title">Action Log</h2>
            </header>
            {runningAction && (
              <span><span className="WPEDashboard__Log__Throbber fa fa-spinner fa-spin" />{`Running ${runningAction} action...`}</span>
            )}
            <div id="log" className="WPE__log__messages" ref={logContainer}>
              {messages.map((message, i) => <LogMessage key={i} message={message} />)}
            </div>
            <div className="WPEDashboard__Log__Buttons">
              <Button aria-label="close" variant="text" size="small" startIcon={<CloseIcon />} onClick={dismissLog}>close</Button>
              <Button aria-label="clear log" variant="text" size="small" startIcon={<DeleteIcon />} onClick={() => clearLog()}>clear</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

WPE.propTypes = {
  sites: PropTypes.instanceOf(Map),
  hostingAccounts: PropTypes.instanceOf(Map),
  fetchHostedSites: PropTypes.func.isRequired,
  fetchHostingAccounts: PropTypes.func.isRequired,
  runAccountAction: PropTypes.func.isRequired,
  dismissLog: PropTypes.func.isRequired,
};

export default WPE;
