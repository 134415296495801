import * as React from 'react';
import moment from 'moment';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as actions from '../../actions/hosted-sites';
import ProgressBar from '../ProgressBar';
import { getSubscriptionData, subscriptionReportColumns } from '../../lib/reports';

const thisMonth = moment().format('MMM, YYYY');

const getCellColor = columnId => {
  switch(columnId) {
    case 'newSubs':
    case 'newSites': {
      return 'success.main';
    }
    case 'cancellationRate':
    case 'cancelledSubs':
    case 'cancelledSites': {
      return 'error.main';
    }
    case 'pendingSubs':
    case 'pendingSites': {
      return 'warning.main';
    }
    case 'totalSites': {
      return 'success.main';
    }
    default: {
      return 'text.primary';
    }
  }
}

const OverviewGrid = ({
  subscriptionData,
}) => {
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item sm={12} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" variant="h5">Net Sites This Month</Typography>
          <Typography component="h4" variant="h1" color="primary">{(subscriptionData.get('monthly').find(row => row.get('month') === thisMonth) || Map()).get('netSites', 0)}</Typography>
        </Paper>
      </Grid>
      <Grid item sm={12} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" variant="h5">Net Subscription Count</Typography>
          <Typography component="h4" variant="h1" color="primary">{subscriptionData.getIn(['totals', 'netSubs'])}</Typography>
        </Paper>
      </Grid>
      <Grid item sm={12} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" variant="h5">Net Site Count</Typography>
          <Typography component="h4" variant="h1" color="primary">{subscriptionData.getIn(['totals', 'netSites'])}</Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}

const MonthlyReport = ({
  subscriptionData,
}) => {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" variant="h6" color="primary" gutterBottom>Monthly Count</Typography>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {subscriptionReportColumns.map(col => (
              <TableCell key={`th-${col.id}`}>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionData.get('monthly').map((row) => (
            <TableRow key={row.get('id')}>
              {subscriptionReportColumns.map(col => (
                <TableCell key={`${row.get('id')}-${col.id}`} sx={{ color: getCellColor(col.id)}}>
                  {row.get(col.id)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={subscriptionReportColumns.length - 1}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: '700' }}>Total</TableCell>
            {subscriptionReportColumns.filter(col => !['month', 'totalSites'].includes(col.id)).map(col => (
              <TableCell key={`total-${col.id}`} sx={{ color: getCellColor(col.id)}}>
                {subscriptionData.getIn(['totals', col.id])}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

const YearlyReport = ({
  subscriptionData,
}) => {
  return (
    <Grid container spacing={3} mt={1}>
      <Grid item sm={12} lg={6}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography component="h3" variant="h6" color="primary" gutterBottom>Yearly Count</Typography>
          <Table size="small" sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell>Net Subscriptions</TableCell>
                <TableCell>Net Sites</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionData.get('yearly').map((row) => (
                <TableRow key={row.get('id')}>
                  <TableCell>{row.get('id')}</TableCell>
                  <TableCell>{row.get('netSubs')}</TableCell>
                  <TableCell>{row.get('netSites')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}

const SubscriptionsReport = ({
  subscriptions,
  fetchWooCommerceSubscriptions,
  isFetchingSubscriptions,
}) => {
  React.useEffect(() => {
    if (subscriptions.size === 0) {
      fetchWooCommerceSubscriptions();
    }
  }, [subscriptions, fetchWooCommerceSubscriptions]);

  if (subscriptions.size === 0 || isFetchingSubscriptions !== false) {
    return <ProgressBar text="Fetching Subscription Data" progress={isFetchingSubscriptions} />
  }

  const subscriptionData = getSubscriptionData(subscriptions);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4, '& th': { fontWeight: '700' } }}>
      <OverviewGrid subscriptionData={subscriptionData} />
      <MonthlyReport subscriptionData={subscriptionData} />
      <YearlyReport subscriptionData={subscriptionData} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptions: state.entities.wc_subscriptions,
    isFetchingSubscriptions: state.ui.getIn(['fetching', 'wc_subscriptions']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWooCommerceSubscriptions: () => dispatch(actions.fetchWooCommerceSubscriptions()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsReport);
