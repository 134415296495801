import React from 'react';
import PropTypes from 'prop-types';

function onSubmit(onEmailSubmit) {
  return (e) => {
    e.preventDefault();
    const input = e.target.querySelector('.user-email');
    const value = input.value;
    onEmailSubmit(value);
  };
}

const EmailForm = ({ onEmailSubmit, heading, disabled }) => (
  <form method="POST" onSubmit={onSubmit(onEmailSubmit)}>
    <label>{heading}</label>
    <div className="form-group">
      <input type="email" className="form-control user-email" placeholder="lol@email.com" />
      <button
        className="btn btn-primary"
        type="submit"
        disabled={disabled}
      >
        Submit
      </button>
    </div>
  </form>
);

EmailForm.propTypes = {
  heading: PropTypes.string.isRequired,
  onEmailSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default EmailForm;
